.warp-page-withdraw {
  padding: 20px 15px 0;
  max-width: 100%;

  .page-withdraw-content {
    max-width: 990px;
    padding: 0 15px;
    margin: auto;

    .back-title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      text-transform: capitalize;
      color: #ffffff;
    }

    .withdraw-content-form {
      @media (min-width: 768px) {
        padding: 29px 0;
      }
      .memo-section-content {
        align-items: center;
        .note-warning-meno {
          border-radius: 4px;
          display: flex;
          background: #3d2604;
          .img-warning {
            width: 21px;
            margin: 25px 10px 0;
          }
          p {
            color: white;
            padding: 20px 20px 20px 0;
          }
        }
        img {
          width: 40px;
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        }
        .tooltip {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);
        }
        .note-required {
          background: #3d2600;
          padding: 0 10px;
          position: relative;
          font-size: 14px;
          color: #d3a107;
          &::before {
            content: '';
            position: absolute;
            background: #3d2600;
            width: 10px;
            height: 10px;
            left: 50%;
            top: -5px;
            transform: rotate(45deg);
            @media (min-width: 525px) {
              left: -4px;
              top: 6px;
            }
          }
        }
      }
      .box-input-select {
        margin-bottom: 40px;

        .input-select-item {
          .box-form-control {
            p {
              font-weight: 700;
              font-size: 16px;
              line-height: 27px;
              @media (min-width: 768px) {
                font-size: 20px;
              }
            }
            .ant-input-group {
              .ant-select {
                .ant-select-selector {
                  padding-left: 8px;
                  background: rgba(255, 255, 255, 0.1) !important;
                  border: 1px solid rgba(255, 255, 255, 0.2);
                  border-radius: 10px;

                  @media (min-width: 991px) {
                    padding-left: 20px;
                  }

                  .ant-select-selection-item {
                    span {
                      img {
                        width: 24px;
                      }
                    }
                    font-size: 16px;
                  }
                }
                .ant-select-arrow {
                  right: 11px;
                  @media (min-width: 991px) {
                    right: 20px;
                    z-index: 0;
                  }
                }
              }
            }
          }
          &:nth-child(2) {
            .ant-select-selection-item {
              font-weight: 400;
              font-size: 18px;
              line-height: 27px;
            }
          }
        }
      }

      .box-expected {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4px;

        .item-expected {
          max-width: 50%;
          width: 50%;
          margin-bottom: 16px;

          p {
            &:first-child {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 27px;
              color: #ffffff;
            }

            &:last-child {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              color: #798c6e;
              margin-bottom: 0;
            }

            @media (min-width: 991px) {
              &:first-child {
                font-size: 20px;
                margin-bottom: 8px;
              }

              &:last-child {
                font-size: 16px;
                line-height: 27px;
              }
            }
          }
        }

        @media (min-width: 991px) {
          margin-bottom: 32px;

          .item-expected {
            margin-bottom: 0;

            p {
              &:first-child {
                font-size: 18px;
                line-height: 27px;
              }

              &:last-child {
                font-size: 16px;
              }
            }
          }
        }
      }

      .box-attention {
        margin-bottom: 16px;

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            position: relative;
            padding: 0 0 0 24px;
            margin-bottom: 8px;

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #798c6e;

              span {
                font-style: normal;
                font-weight: 700;
                background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 8px;
              width: 8px;
              background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
              border-radius: 50%;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .box-discamler {
        padding: 26px 16px;
        background: rgba(0, 104, 67, 0.5);
        border-radius: 10px;

        display: flex;

        > div {
          &:first-child {
            margin-right: 16px;
          }

          &:last-child {
            flex: 1;
            p {
              text-align: justify;
            }
          }
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 150%;
          color: #ffffff;

          &:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #f9aa4b;
            margin-bottom: 4px;
          }
        }
      }

      .withdraw-actions {
        margin-bottom: 60px;
        .error-mess {
          color: #ef466f;
          padding: 32px 0 0;
        }

        & > button {
          margin-top: 32px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding: 80px 75px;

    .page-withdraw-content {
      .withdraw-content-form {
        .memo-section-wrapper {
          margin-bottom: 30px;
          .title-memo {
            font-weight: 700;
            margin-bottom: 8px;
          }
          .token-memo {
            font-weight: 700;
          }
          .memo-section-content {
            align-items: center;
            .note-warning-meno {
              border-radius: 4px;
              display: flex;
              background: #3d2604;
              .img-warning {
                width: 21px;
                margin: 25px 10px 0;
              }
              p {
                color: white;
                padding: 20px 20px 20px 0;
              }
            }
            img {
              width: 40px;
              cursor: pointer;
              &:hover {
                opacity: 0.9;
              }
            }
            .tooltip {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 100%);
            }
            .note-required {
              background: #3d2600;
              padding: 0 10px;
              position: relative;
              font-size: 14px;
              color: #d3a107;
              &::before {
                content: '';
                position: absolute;
                background: #3d2600;
                width: 10px;
                height: 10px;
                left: 50%;
                top: -5px;
                transform: rotate(45deg);
                @media (min-width: 525px) {
                  left: -4px;
                  top: 6px;
                }
              }
            }
          }
        }
        .box-input-select {
          margin-bottom: 32px;
        }

        .box-input-text {
          .input-text-item {
            input {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              &::placeholder {
                color: #798c6e;
              }
            }
          }
        }

        .box-attention {
          margin-bottom: 32px;

          ul {
            li {
              padding: 0 0 0 28px;
              margin-bottom: 14px;

              p {
                font-size: 16px;

                span {
                  font-weight: 700;
                  line-height: 24px;
                }
              }

              &::before {
                height: 11px;
                width: 11px;
              }
            }
          }
        }

        .box-discamler {
          padding: 30px 36px;
          background: rgba(0, 104, 67, 0.5);
          backdrop-filter: blur(300px);
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;

            &:first-child {
              font-weight: 700;
              font-size: 16px;
              line-height: 150%;
              margin-bottom: 8px;
              color: #f9aa4b;
            }
          }
        }
      }
    }
  }
}
