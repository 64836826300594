.box-recent-transaction {
  .recent-transaction-table-amount {
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
  }
  .recent-transaction-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 24px;

    @media (min-width: 991px) {
      font-size: 24px;
    }
  }

  .hisotry-recent-transaction-table .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }

  .ant-table-wrapper {
    padding: 32px 24px;
    background: #00271a;
    border-radius: 15px;
  }

  .ant-table-wrapper .ant-table thead tr > th {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #798c6e;
    padding: 0 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #003c0d;
    white-space: nowrap;
  }

  .ant-table-wrapper .ant-table tbody tr td {
    padding-top: 8px;
    padding-bottom: 8px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
    }

    &:first-child {
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #798c6e;
      }
    }

    .recent-transaction-table-status {
      border-radius: 4px;
      padding: 0 4px;
      text-align: center;

      p {
        font-size: 12px;
      }

      &[data-status='COMPLETED'] {
        background: rgba(52, 117, 59, 0.5);
        p {
          color: #04d100;
        }
      }

      &[data-status='CREATED'] {
        background: rgba(116, 117, 52, 0.5);
        p {
          color: #cdd100;
        }
      }

      &[data-status='PENDING'] {
        background: rgba(116, 117, 52, 0.5);
        p {
          color: #cdd100;
        }
      }
    }
  }

  .ant-table-wrapper .ant-table tbody tr:nth-child(2) td {
    padding-top: 24px;
  }
}
