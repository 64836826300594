.input-select-item {
  margin-bottom: 32px;
  .ant-select-selector {
    background: rgba(5, 40, 8, 0.5);
    // background: rgba(0, 0, 0, 0.1);
    .ant-select-selection-placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }

  .ant-select-clear {
    background: transparent;
    display: none;

    svg {
      fill: #ffffff;
    }
  }

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }

  @media (min-width: 991px) {
    p {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 16px;
    }
  }
}
