.wallet-table-action {
  display: flex;
  align-items: flex-end;

  a {
    &:last-child {
      margin-left: 8px;
    }

    svg {
      height: 12px;
      width: auto;

      path {
        fill: #42fd24;
      }
    }
  }
}
