@import 'assets/css/variables.scss';

.button-secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 36px;
  gap: 8px;
  filter: drop-shadow(1px 3px 4px rgba(127, 255, 67, 0.25));
  border-radius: 50px;
  line-height: 19px;
  cursor: pointer;
  border: 1px solid #ffb905;
  background: #001710;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &[disabled],
  &[data-loading='true'] {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &[data-hoverable='false'] {
    opacity: 1;
    cursor: default;
  }

  .ant-spin {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
