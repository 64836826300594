.warp-page-account-activity {
  // background-color: #141518;
  max-width: 1000px;
  padding: 15px;
  margin: 0 auto;

  overflow: auto;
  height: 70%;
  @media (min-width: 768px) {
    height: unset;
    padding: 42px 15px 0;
  }
}
.activity-btn-logout {
  button {
    display: flex;
    justify-content: center;
    margin: 24px auto;
  }
}
