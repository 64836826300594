@import 'assets/css/variables.scss';

.wrapper-account {
  max-width: 990px;
  padding: 32px 15px 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    padding: 80px 15px;
  }

  .account-banner {
    padding: 16px 20px;
    background: #003c2d;
    border-radius: 30px;
    margin-bottom: 32px;

    @media (min-width: 991px) {
      margin-bottom: 48px;
      padding: 24px 32px;
    }

    .box-information {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      @media (min-width: 991px) {
        margin-bottom: 32px;
      }

      .avatar {
        margin-right: 12px;

        @media (min-width: 991px) {
          margin-right: 30px;
        }

        img {
          width: 100%;
          max-width: 82px;
          max-height: 82px;
          object-fit: contain;
          border-radius: 50%;
          background: rgba(0, 255, 255, 0.2);
          padding: 5px;
          cursor: pointer;

          @media (min-width: 991px) {
            width: 190px;
            height: 190px;
          }
        }
      }

      .name {
        flex: 1;

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #fcfcfd;
          margin-bottom: 0;
          display: flex;
          align-items: center;

          &:last-child {
            font-weight: 400;
            font-size: 12px;
            line-height: 5px;
            margin-bottom: 0;
          }

          @media (min-width: 991px) {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 8px;

            &:last-child {
              font-size: 16px;
              line-height: 16px;
            }
          }
        }

        svg {
          width: 28px;
          margin-left: 16px;
          cursor: pointer;

          path {
            fill: #36fc59;
          }
        }

        input {
          background: transparent;
          border: none;
          outline: none;
          width: 100%;
        }
      }
    }

    .account-action {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: 991px) {
        align-items: center;
        flex-direction: row;
      }

      button {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #ffffff;
        padding: 7px 23px;
        min-width: 180px;
        max-width: 100%;
        height: 30px;
        border: none;
        border-radius: 30px;
        background: transparent;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 8px;

          @media (min-width: 991px) {
            margin-bottom: 0;
          }
        }

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 20px;
          padding: 10px 16px;
          min-width: 210px;
          height: 40px;
        }

        &:hover {
          opacity: 0.7;
        }
        &:active {
          opacity: 1;
        }
      }

      .btn-google-auth {
        background: radial-gradient(212.5% 212.5% at 50.25% -47.5%, #43ff24 0%, rgba(43, 232, 13, 0) 96.35%);
      }

      .btn-change-password {
        background: linear-gradient(71.27deg, rgba(66, 253, 36, 0.8) 14.9%, rgba(19, 255, 241, 0.768) 94.83%);
      }

      .btn-log-out {
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #fffefb;

          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .title-table-recent {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 24px;

    @media (min-width: 991px) {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 32px;
    }
  }
}
