.modal-history-details {
  max-width: 100vw;
  margin: 0 auto;
  height: unset;
  padding-bottom: 0;
  .history-detail-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 27px;
    text-align: center;
  }
  .ant-modal-content {
    padding: 40px 15px;
    border-radius: 30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @media (min-width: 768px) {
      border-radius: 12px;
      padding: 47px 50px;
    }
    .ant-modal-header {
      padding-left: 0;
      margin-bottom: 16px;
    }

    .ant-modal-body {
      padding: 0;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &.modal-history-details-steps {
          counter-reset: section;

          li {
            position: relative;
            min-height: 52px;
            padding-left: 45px;
            display: flex;
            flex-direction: column;

            p {
              margin-bottom: 0;
              font-weight: 700;
              line-height: 27px;
              color: #ffffff;
              text-align: left;
              font-size: 14px;
              @media (min-width: 768px) {
                font-size: 16px;
              }
              &:last-child {
                padding-bottom: 14px;
                font-size: 14px;
                font-weight: 400;
              }
            }

            &::before {
              counter-increment: section;
              content: counter(section);
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
              color: #000000;
              height: 22px;
              width: 22px;
              background: #e6eaed;
              display: flex;
              justify-content: center;
              border-radius: 50%;
              position: absolute;
              z-index: 1;
              left: 0;
              top: 0;
            }

            &:last-child {
              p {
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }

        @media (min-width: 991px) {
          &.modal-history-details-steps {
            counter-reset: section;

            li {
              p {
                font-size: 18px;
                line-height: 27px;

                &:last-child {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .modal-history-details-note {
        margin-bottom: 16px;
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          margin-bottom: 0;
          text-align: left;
          padding-left: 45px;

          &:first-child {
            color: #ffffff;
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 27px;
            text-decoration-line: underline;
            color: #00f4a3;
          }
        }

        @media (min-width: 991px) {
          p {
            font-size: 18px;
            line-height: 27px;
            &:last-child {
              font-size: 18px;
            }
          }
        }
      }

      .modal-history-details-info {
        .modal-history-details-info-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;

          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
            margin-bottom: 0;

            &:first-child {
              font-weight: 600;
              font-size: 14px;
              text-align: left;
              margin-right: 16px;
            }

            &:last-child {
              text-align: right;
            }
            span,
            a {
              color: #389201;
              font-weight: 700;
            }
          }

          &:nth-child(2) {
            p {
              &:last-child {
                font-weight: 500;
              }
            }
          }

          &:nth-child(4) {
            p {
              &:last-child {
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;

                border-radius: 3px;
                min-width: 100px;
                min-height: 24px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;

            p {
              &:last-child {
                line-height: 16px;
                color: #00c2fe;
                word-break: break-all;
                word-wrap: break-word;
                text-align: right;
              }
            }
          }
        }

        @media (min-width: 991px) {
          .modal-history-details-info-item {
            margin-bottom: 6px;

            p {
              font-size: 14px;
              line-height: 24px;

              &:first-child {
                font-size: 18px;
              }
              &:last-child {
                a {
                  color: #389201;
                }
              }
            }

            &:nth-child(5) {
              p {
                &:last-child {
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }

    .modal-scan-action-bg {
      position: absolute;
      height: 70%;
      width: 70%;
      // background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
      filter: blur(250px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
    }

    @media (min-width: 991px) {
      padding: 20px 60px;
    }
  }
}
.ant-modal-wrap {
  top: unset;
  overflow: hidden;
  // padding-bottom: 50px;
  @media (min-width: 768px) {
    top: 0;
    overflow: auto;
    padding-bottom: 0;
  }
}
