.box-show-address-text {
  display: flex;
  align-items: center;
  margin-bottom: 0px;

  > div {
    &:first-child {
      border: 1px solid #34384c;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 7px 15px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      height: 50px;
      flex: 1;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #fcfcfd;
        margin-bottom: 0;
        white-space: break-spaces;
        word-break: break-word;
      }

      @media (min-width: 991px) {
        padding: 14px 29px;
        margin-right: 26px;

        p {
          font-size: 16px;
        }
      }
    }

    &:last-child {
      position: relative;

      img {
        width: 35px;
        cursor: pointer;

        &:first-child {
          margin-right: 8px;
        }

        @media (min-width: 991px) {
          width: 50px;

          &:first-child {
            margin-right: 16px;
          }
        }
      }

      .tooltip {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
    }
  }

  @media (min-width: 991px) {
    margin-bottom: 26px;
  }
}

.ant-modal-root {
  .ant-modal-mask {
    background: unset;
    backdrop-filter: blur(2px);
    .ant-modal-wrap {
      top: unset;
      overflow: hidden;
      bottom: 100px;
      padding: 0;
      @media (min-width: 991px) {
        vertical-align: middle;
        top: 0;
        overflow: auto;
      }
    }
    .ant-modal-centered {
      top: unset !important;
      overflow: hidden;
    }
  }
}
