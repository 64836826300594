@import 'assets/css/variables.scss';
.form-verify-forgot {
  width: 100%;
  max-width: 687px;

  .verify-code {
    position: relative;
    padding: 15.26px 14px 26px 14px;

    @media (min-width: 991px) {
      padding: 32px;
    }

    > img {
      margin-bottom: 16px;
      max-width: 95px;

      @media (min-width: 991px) {
        max-width: 100%;
      }
    }

    a {
      svg {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 20px;

        @media (min-width: 991px) {
          top: 37px;
          right: 37px;
          width: 30px;
        }
      }
    }

    .verify-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 16px;

      @media (min-width: 991px) {
        font-size: 24px;
        margin-bottom: 32px;
      }
    }
    .verify-resend {
      display: flex;
      justify-content: space-between;
      margin-top: 14px;
      .time-count {
        color: #fff;
        font-weight: 400;
        line-height: 14px;
        font-size: 14px;
      }
      > a {
        color: #14d19d;

        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        cursor: pointer;

        @include media-up(sm) {
          font-size: 16px;
        }
      }
      a:hover {
        opacity: 0.7;
      }
      a:active {
        opacity: 1;
      }
    }
    .verify-error {
      color: $warning;
      font-size: 13px;
      text-align: center;
      padding: 10px 10px;
      @include media-up(sm) {
        font-size: 16px;
      }
    }

    .verify-action {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      row-gap: 20px;
    }

    .verify-input {
      margin-top: 14px;
      p {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;

        @media (min-width: 576px) {
          font-size: 18px;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      .ant-form-item-explain {
        margin-bottom: -15px;
        text-align: left;
      }
    }
  }
}
