.wrap-card-ref {
  .box-info-sponserkey {
    background: rgb(0, 81, 42, 0.5);
    backdrop-filter: blur(300px);
    border-radius: 8px;
    padding: 15px;

    @media (min-width: 768px) {
      padding: 24px 20px;
    }
  }
}
