@import 'assets/css/variables.scss';

.auth-card {
  width: 100%;
  padding: 16px 12px;
  border-radius: 15px;
  z-index: 2;
  max-width: 687px;
  background: #052c22;
  opacity: 0.6;
  backdrop-filter: blur(15px);
  box-shadow: inset 0px 2px 5px rgba(111, 255, 238, 0.15);

  @include media-up(lg) {
    padding: 48px 60px;
  }
}
