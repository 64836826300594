.modal-qrcode-deposit {
  position: relative;
  background: #1a3035;
  bottom: 0;
  height: unset;
  max-width: 100vw;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0;
  margin: 50px auto;

  @media (min-width: 991px) {
    border-radius: 12px;
    max-width: unset;
  }

  .modal-qrcode-deposit-action {
    .button-submit {
      width: 100px;
      height: 35px;
      border-radius: 35px;
      padding: 10px 28px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
    }

    .modal-qrcode-deposit-action-share {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #249105;
      padding: 10px 25px;
      border: 1px solid #249005;
      border-radius: 30px;
      background: transparent;
      margin-right: 15px;
    }

    @media (min-width: 991px) {
      .button-submit {
        width: 120px;
        height: 40px;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 38px;
      }

      .modal-qrcode-deposit-action-share {
        font-size: 16px;
        line-height: 20px;
        padding: 10px 31px;
        margin-right: 32px;
      }
    }
  }

  .sub-model {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;

    @media (min-width: 991px) {
      font-size: 16px;
    }

    span {
      background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .ant-modal-close {
    top: 56px;
    right: 55px;

    .ant-modal-close-x {
      border: 2px solid #353945;
      box-sizing: border-box;
      border-radius: 48px;
      height: 20px;
      width: 20px;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        svg {
          width: 10px;

          path {
            fill: #ffffff;
          }
        }
      }

      @media (min-width: 991px) {
        height: 30px;
        width: 30px;

        .anticon {
          svg {
            width: 17px;
          }
        }
      }
    }
  }
  .ant-modal-content {
    @media (min-width: 991px) {
      border-radius: 12px;
    }
    .ant-modal-header {
      border-bottom: none;
      background: transparent;
      padding: 52px 52px 0;

      .ant-modal-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
      }

      @media (min-width: 991px) {
        display: block;

        .ant-modal-title {
          font-size: 32px;
          line-height: 39px;
        }
      }
    }

    .ant-modal-body {
      text-align: center;
      padding: 52px;

      .modal-deposit-qs-code {
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 250px;
        margin: 0 auto;
        margin-bottom: 16px;
        border: 2px solid white;
        @media (min-width: 991px) {
          max-width: 300px;
          max-height: 300px;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #777e91;
        margin-bottom: 48px;

        &.title-modal {
          font-weight: 600;
          font-size: 24px;
          line-height: 130%;
          color: #ffffff;
          margin-bottom: 4px;
        }

        @media (min-width: 991px) {
          font-size: 16px;

          &.title-modal {
            display: none;
          }
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }

  .ant-modal {
    max-width: 100vw !important;
    width: 100%;
    // bottom: 0;
    top: unset;
    position: fixed;
    @media (min-width: 576px) {
      max-width: unset;
      position: unset;
    }
  }
}
