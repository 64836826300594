.wrapper-item-account-activity {
  .item-account-activity {
    padding: 12px 13.5px;
    background: #00271a;

    &:not(:last-child) {
      border-bottom: 0.5px solid #798c6e;
    }

    &:first-child {
      border-radius: 10px 10px 0 0;
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 14px;
      }

      &:first-child {
        p {
          font-size: 14px;

          &:last-child {
            background: linear-gradient(89.87deg, #3f9400 0.09%, #1ec203 95.39%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            cursor: pointer;
          }
        }
      }

      p {
        font-style: normal;
        font-weight: 500;
        line-height: 150%;

        &:first-child {
          color: #798c6e;
        }

        &:last-child {
          color: #ffffff;
        }
      }
    }
  }
}
