@import 'assets/css/variables.scss';

$panel-width: 340px;

.private-layout-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;

  .collapse-icon {
    display: flex;
    align-items: center;
    transform: scale(-1);
    z-index: 11;
    input#menu {
      display: none;
    }

    .icon {
      width: 34px;
      height: auto;
      padding: 0;
      display: block;
      cursor: pointer;
    }

    .icon .menu,
    .icon .menu::before,
    .icon .menu::after {
      background: #fff;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
    }
    .icon .menu::before {
      width: 28px;
    }
    .icon .menu {
      width: 24px;
    }
    .icon .menu::after {
      width: 34px;
    }

    .icon:hover .menu,
    .icon:hover .menu::before,
    .icon:hover .menu::after {
      background: #fff;
    }

    .icon .menu::before {
      top: -8px;
    }

    .icon .menu::after {
      top: 8px;
    }

    #menu:checked + .icon .menu {
      background: transparent;
    }

    #menu:checked + .icon .menu::before {
      width: 28px;
      transform: rotate(45deg);
    }

    #menu:checked + .icon .menu::after {
      width: 28px;
      transform: rotate(-45deg);
    }

    #menu:checked + .icon .menu::before,
    #menu:checked + .icon .menu::after {
      top: 0;
      transition: top ease 0.3s, transform ease 0.3s 0.3s;
    }
  }
  .panel-container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #001710;
    border-radius: 20px;
    height: 900px;
    max-height: 100vh;

    width: $panel-width;
    display: none;
    @include media-up(lg) {
      display: block;
    }

    .panel-body {
      width: 100%;
      height: 100%;
      max-width: 100%;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      .panel-top {
        .panel-content {
          max-height: calc(100vh - 130px);
          overflow: auto;
          padding-left: 75px;
        }

        .panel-logo {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 130px;
          cursor: pointer;

          img {
            position: relative;
            z-index: 1;
            width: 100%;
            max-width: 150px;

            &:not(:first-child) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 0;
              transform-origin: center;
            }

            &:nth-child(2) {
              animation: rotateAnimation 6s linear infinite;
            }

            &:nth-child(3) {
              animation: rotateAnimation 7s linear infinite;
            }

            &:nth-child(4) {
              animation: rotateAnimation 8s linear infinite;
            }
          }
        }
        ul.panel-link {
          list-style: none;
          display: flex;
          flex-direction: column;

          &:not(:last-child) {
            padding: 40px 0;
            margin-bottom: 24px;
            border-bottom: 1.03775px solid #07462d;
          }

          > p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #bcbcbc;
            margin-bottom: 16px;
          }

          li {
            &:not(:last-child) {
              margin-bottom: 16px;
            }

            a {
              color: #fff;
              fill: #fff;
              font-weight: 700;
              font-style: normal;
              font-size: 14px;
              line-height: 26px;
              display: flex;
              align-items: center;
              flex-direction: row;
              flex-wrap: nowrap;

              padding: 8px 10px;

              svg {
                width: 20px;
                margin-right: 16px;
              }

              img {
                width: 32px;
                height: auto;
                margin-right: 16px;
              }
            }

            .menu-label {
              width: fit-content;
              position: relative;
              .menu-tag {
                font-size: 10px;
                font-weight: bold;
                line-height: 1;

                height: fit-content;
                padding: 6px 8px;
                // background: #00e9ff;
                border: 1px solid #00e9ff;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
                  rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
                border-radius: 30px;

                position: absolute;
                top: -8px;
                right: -5px;
                transform: translateX(100%);
                text-transform: uppercase;
                width: max-content;

                text-transform: uppercase;
              }
            }

            .ant-select {
              color: #fff;

              .ant-select-selector {
                background-color: transparent;
                border: none;
              }

              .ant-select-arrow {
                display: none;
              }
            }
          }
          li.active,
          li:hover {
            a {
              background-color: rgba($color: #ffffff, $alpha: 0.1);
              border-radius: 8px;
            }
          }

          .menu-language {
            display: flex;
            align-items: center;
            padding: 24px 10px 8px 10px;
            border-top: 1px solid #07462d;

            .text-flag {
              color: #fff;
              fill: #fff;
              font-weight: 700;
              font-style: normal;
              font-size: 14px;
              line-height: 26px;
            }
          }
        }
      }
      .panel-footer {
        margin-bottom: 20px;
        h5 {
          color: #ffffff;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        p {
          color: #bfbfbf;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          margin-top: 10px;
        }
        .connect-now {
          button {
            fill: #2a4178;
            color: #2a4178;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            width: 100%;
            height: 40px;
            margin-top: 16px;
            background: #ffffff;
            border-radius: 8px;
            border: unset;
            cursor: pointer;

            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;

            &:hover {
              opacity: 0.7;
            }
            &:active {
              opacity: 1;
            }

            svg {
              margin-right: 8px;
            }
          }
        }

        .term-privacy {
          color: #b9b9b9;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: block;
          margin-top: 20px;
          max-width: 120px;
          &:hover {
            text-decoration: underline;
          }
        }

        .version {
          color: #b9b9b9;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-top: 20px;
        }
      }
    }
  }

  .private-content {
    width: 100%;
    margin-left: auto;
    padding-bottom: 50px;

    position: relative;

    @include media-up(lg) {
      padding-bottom: 100px;
      width: calc(100% - $panel-width);
      padding-bottom: 0;
    }
  }
}
