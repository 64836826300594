@import 'assets/css/variables.scss';

.private-footer {
  width: 100%;
  min-height: 390px;
  padding: 8px 24px 42px 24px;
  background: #011804;
  display: flex;
  align-items: center;

  @media (min-width: 991px) {
    padding: 30px 75px 42px 75px;
  }

  // display: none;
  // @include media-up(lg) {
  //   display: block;
  // }

  .private-footer-content {
    max-width: 1300px;
    margin: auto;

    ul {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      list-style: none;
      margin: 0;

      li {
        padding: 4px 16px;

        a {
          color: #ededed;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;

          &:hover {
            color: #fff;
          }
        }

        @media (min-width: 991px) {
          padding: 12px;

          a {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    .wrapper-logo-footer {
      text-align: center;

      img {
        max-width: 100%;
        margin-bottom: 8px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 161%;
        text-align: center;
        color: #e4e4e4;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 161%;
          text-align: center;
          color: #faff00;
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 50px;

        p {
          font-size: 16px;
          line-height: 20px;

          span {
            font-size: 16px;
            line-height: 20px;
          }
        }

        img {
          margin-bottom: 50px;
        }
      }
    }

    .wrapper-footer-center {
      .menu-footer {
        flex-direction: column;
        margin-bottom: 16px;

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #e4e4e4;
        }

        @media (min-width: 991px) {
          margin-bottom: 38px;
        }
      }

      img {
        width: 30px;

        @media (min-width: 991px) {
          width: auto;
        }
      }
    }

    .wrapper-footer-right {
      @media (min-width: 991px) {
        width: 90%;
      }

      > p {
        position: relative;
        z-index: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 16px;

        @media (min-width: 991px) {
          margin-bottom: 36px;
        }
      }

      ul {
        position: relative;
        z-index: 1;

        li {
          padding: 4px 6px;

          img {
            width: 20px;

            @media (min-width: 991px) {
              width: 30px;
            }
          }
        }
      }

      .wrapper-support-chat {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, 0%);
          z-index: 0;
          background: radial-gradient(
            50.07% 49.93% at 50.2% 49.93%,
            #ffe391 0%,
            #ffd34e 9%,
            #956c25 20%,
            #6e4f1b 26%,
            #4c3613 32%,
            #30220c 38%,
            #1a1306 45%,
            #0b0802 53%,
            #020100 61%,
            #000000 74%
          );
          background-blend-mode: screen;
          mix-blend-mode: screen;
        }

        .support-chat {
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          min-height: 154px;
          background: radial-gradient(70.78% 70.78% at 50% 63.64%, #82fd5a 0%, #1a6e00 100%);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          margin-bottom: 24px;

          p {
            font-style: normal;
            font-weight: 700;
            font-size: 8px;
            line-height: 150%;
            text-align: center;
            color: #0d5315;

            &:last-child {
              font-size: 16px;
              line-height: 150%;
            }

            @media (min-width: 991px) {
              font-size: 12px;
              line-height: 15px;

              &:last-child {
                font-size: 24px;
                line-height: 29px;
              }
            }
          }
        }
      }
    }

    .footer-version {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        text-align: center;
      }
    }
  }
}
