@import 'assets/css/variables.scss';

.wrapper-form-forgot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  @media screen and (min-height: 400px) {
    align-items: center;
  }

  .forgot-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 16px;

    @include media-up(lg) {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 32px;
    }
  }

  .forgot-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 0;

    @media (min-width: 991px) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  .form-forgot {
    position: relative;
    max-width: 687px;
    padding: 15.26px 14px 26px 14px;

    @media (min-width: 991px) {
      padding: 24px;
    }

    > img {
      width: auto;
      max-width: 95px;
      margin-bottom: 16px;

      @media (min-width: 991px) {
        max-width: 100%;
      }
    }

    svg {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 20px;

      @media (min-width: 991px) {
        top: 37px;
        right: 37px;
        width: 30px;
      }
    }

    .ant-form-item {
      margin-bottom: 16px;

      @media (min-width: 991px) {
        margin-bottom: 32px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .ant-form-item-explain {
        margin-bottom: -15px;
        text-align: left;
      }
    }

    @include media-up(lg) {
      padding: 32px;
    }

    .form-action {
      width: 100%;
      max-width: 187px;

      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      @include media-up(sm) {
        max-width: 287px;
      }
    }
  }
}
