@import 'assets/css/variables.scss';

.custom-modal {
  font-weight: 400;
  font-size: 32px;
  line-height: 27px;

  .button-submit {
    border-radius: 30px;
    max-width: 120px !important;
    width: 100%;
    padding: 10px 32px;
    height: 35px;

    @media (min-width: 991px) {
      max-width: 223px !important;
      padding: 10px 20px;
      height: 45px;
    }
  }

  .ant-modal-close {
    top: 25px;
    right: 25px;

    .ant-modal-close-x {
      width: 30px;
      height: 30px;
      line-height: 0;

      border: 2px solid #353945;
      border-radius: 48px;

      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }

  .ant-modal-header {
    border-bottom: none;
    background: transparent;
    @include media-down(sm) {
      padding: 16px 0;
    }
    .ant-modal-title {
      color: #ffffff;
      font-weight: 600;
      font-size: 32px;
      line-height: 27px;
      // max-width: 91%;
      @include media-down(sm) {
        font-size: 22px;
      }
    }
  }

  .ant-modal-body {
    @include media-down(sm) {
      padding: 24px 0;
    }
  }

  .ant-modal-content {
    background: #001f14;
    box-shadow: none;
    @include media-down(sm) {
      border-radius: 12px;
    }
  }

  .ant-modal-footer {
    border: unset;
    border-radius: unset;
    text-align: center;

    button {
      max-width: 200px;
    }
  }
}
