@import 'assets/css/variables.scss';

.wrapper-private-header-mobile {
  padding-top: 32px;
  position: relative;
  z-index: 11;
  width: 100%;

  @media (min-width: 991px) {
    padding-top: 0;
  }

  &.active {
    position: fixed;
    background: #001710;
  }

  .private-header-mobile {
    height: 80px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    max-width: 990px;
    margin: auto;
    padding: 0 15px;

    @media (min-width: 991px) {
      margin-top: 0;
    }

    @include media-up(xl) {
      height: 130px;
      position: relative;
    }

    .header-left {
      display: flex;
      max-width: 410px;
      margin-right: 32px;
      width: 100%;
      flex: 1;

      &.has-title {
        display: none;

        @media (min-width: 991px) {
          display: block;
        }
      }

      &.header-left-title {
        display: flex;
        align-items: center;

        @media (min-width: 991px) {
          display: none;
        }

        svg {
          margin-right: 12px;
        }

        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
        }
      }

      @media (min-width: 991px) {
        display: block;
      }

      .panel-logo {
        position: relative;
        display: flex;
        align-items: center;
        height: 130px;
        cursor: pointer;

        @media (min-width: 991px) {
          display: none;
        }

        img {
          position: relative;
          z-index: 1;
          width: 79px;
          height: 68px;
          max-width: 80px;

          &:not(:first-child) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
            transform-origin: center;
          }

          &:nth-child(2) {
            animation: rotateAnimation 6s linear infinite;
          }

          &:nth-child(3) {
            animation: rotateAnimation 7s linear infinite;
          }

          &:nth-child(4) {
            animation: rotateAnimation 8s linear infinite;
          }
        }
      }

      .header-left-box-search {
        position: relative;
        display: none;

        @media (min-width: 991px) {
          display: block;
        }

        input {
          background: rgba(0, 224, 117, 0.2);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px;
          height: 60px;
          padding-left: 68px;
          border: none;
          outline: none;
          width: 100%;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
        }
      }

      .header-go-back {
        svg {
          width: 16px;
          @include media-up(lg) {
            display: none;
          }
        }
        h2 {
          font-size: 24px;
          @include media-up(lg) {
            display: none;
          }
        }
      }
      .header-logo {
        cursor: pointer;
        img {
          width: 100%;
          max-width: 140px;
          object-fit: contain;
          // margin-right: 10px;
        }
      }
      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 100%;
        color: #ffffff;
        margin-bottom: 0;
        @include media-up(lg) {
          display: none;
        }
      }
    }
    .header-right {
      flex-wrap: wrap-reverse;
      justify-content: flex-end;
      align-items: center;
      display: flex;

      .avatar-image {
        width: fit-content;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        cursor: pointer;
        & > div {
          width: 40px;
          height: 40px;
          padding: 2px;
          border-radius: 100%;
          background: linear-gradient(to left, rgba(0, 117, 254, 1) 0%, rgba(0, 194, 254, 1) 100%);
          overflow: hidden;
        }
        span:first-child {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 100%;
          background: #000;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          margin-right: 20px;
        }
        span:last-child {
          color: #fcfcfd;
          font-weight: 600;
          font-size: 16px;
          line-height: 130%;
          white-space: nowrap;
        }
      }
      .select-chain {
        height: 100%;
        width: 120px;
        margin-right: 22px;
        .ant-select-selector {
          background: transparent;
          border-radius: 10px;
        }
        .ant-select-selection-item {
          color: #fff;
        }
        .ant-select-arrow {
          color: #fff;
        }
      }

      a {
        &:not(:last-child) {
          button {
            margin-right: 16px;
          }
        }
      }

      .toggle-menu {
        position: relative;
        height: 42px;
        width: 55px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        direction: rtl;
        margin-right: 0;
        margin-left: auto;
        margin-left: 24px;

        @media (min-width: 991px) {
          display: none;
        }

        &.active {
          span {
            &:nth-child(1) {
              position: absolute;
              height: 4%;
              width: 60%;
              top: 50%;
              background-color: #ffffff;
              transform-origin: center;
              transform: rotate(45deg);
              transition: 0.4s;
            }

            &:nth-child(2) {
              display: none;
              transition: 0.4s;
            }

            &:nth-child(3) {
              position: absolute;
              height: 4%;
              width: 60%;
              top: 50%;
              transform-origin: center;
              transform: rotate(-45deg);
              transition: 0.4s;
            }
          }
        }

        span {
          display: block;

          &:nth-child(1) {
            height: 16.6666666%;
            width: 60%;
            background-color: #ffffff;
            transition: 0.4s;
          }

          &:nth-child(2) {
            height: 16.6666666%;
            width: 100%;
            background-color: #ffffff;
            transition: 0.4s;
          }

          &:nth-child(3) {
            height: 16.6666666%;
            width: 80%;
            background-color: #ffffff;
            transition: 0.4s;
          }
        }
      }
    }
  }
}

/*
* Menu content mobile
*/
.menu-content-mobile {
  position: fixed;
  // top: 80px;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 0px;
  background: $secondary;
  z-index: 10;
  transition: ease-in-out 0.4s;
  opacity: 0;
  overflow: auto;

  visibility: hidden;
  &[data-visible='true'] {
    visibility: unset;
    height: 100%;
    opacity: 1;
  }
  .collapse-icon {
    display: flex;
    align-items: center;
    transform: scale(-1);
    margin-top: 30px;
    margin-right: 20px;
    input#menu {
      display: none;
    }

    .icon {
      width: 34px;
      height: auto;
      padding: 0;
      display: block;
      cursor: pointer;
    }

    .icon .menu,
    .icon .menu::before,
    .icon .menu::after {
      background: #fff;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
    }
    .icon .menu::before {
      width: 28px;
    }
    .icon .menu {
      width: 24px;
    }
    .icon .menu::after {
      width: 34px;
    }

    .icon:hover .menu,
    .icon:hover .menu::before,
    .icon:hover .menu::after {
      background: #fff;
    }

    .icon .menu::before {
      top: -8px;
    }

    .icon .menu::after {
      top: 8px;
    }

    #menu:checked + .icon .menu {
      background: transparent;
    }

    #menu:checked + .icon .menu::before {
      width: 28px;
      transform: rotate(45deg);
    }

    #menu:checked + .icon .menu::after {
      width: 28px;
      transform: rotate(-45deg);
    }

    #menu:checked + .icon .menu::before,
    #menu:checked + .icon .menu::after {
      top: 0;
      transition: top ease 0.3s, transform ease 0.3s 0.3s;
    }
  }
  .menu-body {
    min-height: 500px;
  }

  /*
  * Menu mobile Container 
  */
  ul.menu-link {
    list-style: none;
    margin: 16px 0 0;
    flex-flow: column nowrap;

    li {
      white-space: nowrap;
      padding: 16px 30px;
      a {
        color: rgba(93, 101, 136, 1);

        font-weight: 600;
        font-size: 18px;
        line-height: 160%;

        width: 100%;
        display: block;
        align-items: center;
        text-align: center;
      }
    }
    li.active {
      position: relative;
      a {
        color: #ffffff;
      }
    }
  }

  .menu-actions-mobile {
    width: 100%;
    padding: 30px 24px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    a {
      width: 100%;
      max-width: 127px;
      display: block;
    }
    & > a:first-child {
      margin-right: 10px;
    }
    & > a:last-child {
      margin-left: 10px;
    }
  }
}
