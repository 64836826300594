.box-amount {
  margin-bottom: 32px;

  .amount-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    @media (min-width: 991px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 0;
    }
  }

  .amount-available {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 12px;

    p {
      font-weight: 400;
      font-size: 14px;
      color: #a5adcf;

      @media (min-width: 991px) {
        line-height: 24px;
        font-size: 16px;
      }

      &:first-child {
        color: #ef466f;
      }

      // &:last-child {
      //   display: none;
      //   @media (min-width: 991px) {
      //     display: block;
      //   }
      // }

      span {
        font-weight: 600;
        line-height: 27px;
        color: #ffffff;

        &:first-child {
          color: #798c6e;
        }

        &:last-child {
          margin-left: 6px;

          span {
            color: #ffffff;
          }
        }
      }
    }
  }

  .box-input-amount {
    position: relative;
    margin-bottom: 8px;

    input {
      height: 43px;
      border: 1px solid #34384c;
      box-sizing: border-box;
      border-radius: 5px;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      background: transparent;
      padding: 10px 16px;
      padding-right: 60px;
      width: 100%;
      outline: none;
      &::placeholder {
        color: #798c6e;
      }
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    button {
      border: none;
      outline: none;
      background: transparent;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #ffffff;
      padding: 8px 16px;
      cursor: pointer;
    }
  }
  .wrapper-availabel-fee {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .availabel-bottom {
      display: block;

      @media (min-width: 991px) {
        display: none;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: #a5adcf;

        @media (min-width: 991px) {
          line-height: 24px;
          font-size: 16px;
        }

        span {
          margin-left: 8px;
          font-weight: 600;
          line-height: 27px;
          color: #ffffff;

          @media (min-width: 991px) {
            margin-left: 40px;
          }
        }
      }
    }

    .amount-fee {
      display: flex;
      // align-items: center;
      justify-content: flex-end;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #a5adcf;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        span {
          font-weight: 500;
          color: #ffffff;
          margin-left: 8px;
          text-align: right;
          @media (min-width: 991px) {
            margin-left: 30px;
          }
        }

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (min-width: 991px) {
    margin-bottom: 32px;

    .amount-title {
      line-height: 27px;
      margin-bottom: 0;
    }

    .amount-avaiable {
      margin-bottom: 16px;
    }
    .box-input-amount {
      margin-bottom: 16px;
    }
  }
}
