@import 'assets/css/variables.scss';

.warp-page-primary-kyc {
  padding: 20px 15px 0;
  max-width: 100%;

  .page-primary-kyc-content {
    max-width: 1000px;
    margin: auto;
    .go-back {
      display: none;
      @media (min-width: 991px) {
        display: flex;
      }
    }
    .primary-kyc-content-form {
      padding: 29px 0;

      .box-input-select {
        margin-bottom: 32px;
      }

      .box-input-text {
        margin-bottom: 0px;

        .ant-form-item {
          width: 100%;
        }

        .form-item-calendar {
          .ant-form-item-control-input-content {
            p {
              font-weight: 700;
              font-size: 16px;
              margin-bottom: 8px;
              line-height: 27px;
              color: #ffffff;
            }

            > div {
              display: flex;
              align-items: flex-end;
            }
          }

          .ant-picker {
            margin-right: 10px;
            background-color: transparent;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #a5adcf;
            width: 100%;
            height: 50px;
            border: 1px solid #34384c;
            border-radius: 8px;
          }

          .ant-picker-input > input {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;

            &::placeholder {
              color: #a5adcf;
            }
          }
        }
      }

      .box-input-file {
        margin-bottom: 32px;

        .primary-kyc-update-card-photo {
          display: flex;
          flex-direction: column;
          img {
            width: 100%;
          }
        }

        .primary-kyc-update-card-photo-title {
          .input-file-item-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #ffffff;
            margin-bottom: 8px;
          }

          .input-file-item-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #a5adcf;
            margin-bottom: 32px;
          }
        }

        .input-file-item {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 32px;
          }

          .top-sub {
            display: none;
          }

          .input-file-item-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #ffffff;
            margin-bottom: 8px;
          }

          .input-file-item-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #a5adcf;
            margin-bottom: 32px;
          }

          .ant-upload.ant-upload-drag {
            background-color: transparent;
            border: 2px dashed rgba(255, 255, 255, 0.2);
            border-radius: 24px;

            img {
              max-height: 280px;
            }
          }

          .ant-upload.ant-upload-btn {
            display: flex !important;
            align-items: center;
            justify-content: center;
            padding: 32px 40px;
          }

          .ant-upload-drag-icon {
            margin-bottom: 18px;

            img {
              max-width: 100%;
            }
          }

          .ant-upload-text {
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            font-feature-settings: 'cv11' on, 'cv02' on, 'cv03' on, 'cv04' on;
            color: #ffffff;
          }

          .box-input {
            max-width: 100%;
            justify-content: center;

            .image-example {
              display: none;
            }
          }
        }
      }

      .ant-select-selector {
        background: rgba(19, 19, 19, 0.5);
      }
    }
  }

  @media (min-width: 991px) {
    padding: 42px 15px 0;

    .page-primary-kyc-content {
      .go-back {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 48px;
        line-height: 61px;
        color: #ffffff;
      }

      .primary-kyc-content-form {
        padding: 30px 50px 80px;

        .box-input-text {
          margin-bottom: 32px;
          display: flex;
          flex-wrap: wrap;

          .ant-row {
            .ant-col-24.ant-col-lg-12 {
              &:first-child {
                padding-right: 16px;
              }

              &:last-child {
                padding-left: 16px;
              }
            }
          }

          .input-text-item {
            &:nth-child(2),
            &:nth-child(3) {
              max-width: 50%;
            }

            &:nth-child(2) {
              padding-right: 8px;
            }

            &:nth-child(3) {
              padding-left: 8px;
            }
          }

          .form-item-calendar {
            .ant-form-item-control-input-content {
              p {
                font-size: 20px;
                margin-bottom: 16px;
              }
            }

            .ant-picker {
              margin-right: 20px;
            }
          }
        }

        .box-input-file {
          margin-bottom: 48px;

          .primary-kyc-update-card-photo {
            display: flex;
            flex-direction: row;
            margin-bottom: 48px;

            .input-file-item {
              padding-right: 32px;
              width: 100%;
              max-width: 50%;

              &:last-child {
                padding-right: 0;
                padding-left: 32px;
              }
            }
          }

          .primary-kyc-update-card-photo-title {
            .input-file-item-title {
              font-size: 18px;
              line-height: 27px;
            }

            .input-file-item-description {
              font-size: 16px;
              line-height: 24px;
            }
          }

          .input-file-item {
            margin-bottom: 0 !important;

            .top-sub {
              display: block;

              font-weight: 700;
              font-size: 16px;
              line-height: 150%;
              font-feature-settings: 'cv11' on, 'cv02' on, 'cv03' on, 'cv04' on;
              color: #ffffff;
            }

            .input-file-item-title {
              font-size: 18px;
              line-height: 27px;
            }

            .input-file-item-description {
              font-size: 16px;
              line-height: 24px;
            }

            .ant-upload.ant-upload-drag {
              border: 2px dashed #344c48;
              border-radius: 15px;
            }

            .ant-upload-text {
              font-size: 18px;
            }

            &:last-child {
              .box-input {
                .ant-upload {
                  display: block;
                  @include media-up(lg) {
                    max-width: 100%;
                    img {
                      width: 100%;
                    }
                  }

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .page-primary-kyc-content {
      .primary-kyc-content-form {
        .box-input-file {
          .input-file-item {
            .box-input {
              display: flex;
              // align-items: center;
              // flex: 1;

              > span {
                padding-right: 40px;
                display: flex;
                align-items: stretch;
                flex: 0 0 50%;
                max-width: 50%;
              }

              .image-example {
                padding-left: 40px;
                display: flex;
                align-items: center;
                flex: 0 0 50%;
                max-width: 50%;

                p {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #a5adcf;
                  margin-right: 16px;
                }

                img {
                  max-width: 100%;
                  width: 100%;
                  border: 2px dashed #344c48;
                  border-radius: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
