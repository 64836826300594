@import 'assets/css/variables.scss';

.wrapper-form-signup {
  width: 100%;
  height: 100%;
  max-width: 687px;
  margin: 0 auto;
  padding-top: 0;

  @include media-up(lg) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-signup {
    width: 100%;
    max-width: unset;
    margin: 0 auto;
    padding: 12px 13px 24px 13px;

    @media (min-width: 991px) {
      padding: 32px;
    }

    .form-signup-container {
      .form-title,
      .form-title-country {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
        margin-bottom: 0px;

        @media (min-width: 991px) {
          font-size: 20px;
          margin-bottom: 8px;
        }
      }

      .form-title-country {
        margin-bottom: 0;
      }

      .title-private {
        color: #00f4a3;
        font-weight: 400;
      }

      .form-action {
        width: 100%;
        max-width: 187px;
        margin: 0 auto;

        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        @include media-up(sm) {
          max-width: 287px;
        }
      }

      .ant-checkbox-wrapper {
        width: 100%;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .ant-checkbox-inner {
          border-color: #35e73e;
          background-color: transparent;
        }
      }

      .ant-form-item {
        margin-bottom: 16px;

        @media (min-width: 991px) {
          margin-bottom: 32px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .ant-form-item-explain {
          margin-bottom: -15px;
        }
      }

      .form-item-checkbox {
        .ant-checkbox {
          position: relative;
          top: 50%;

          @media (min-width: 991px) {
            transform: translateY(-50%);
          }
        }
      }
    }

    .header-auth-card {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      @media (min-width: 991px) {
        margin-bottom: 32px;
      }

      div {
        display: flex;
        align-items: center;

        a {
          display: flex;
          align-items: center;
          height: 44px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #fdfdfd;

          @media (min-width: 991px) {
            height: 56px;
            font-size: 24px;
          }

          &.active {
            span {
              font-style: normal;
              font-weight: 700;
              background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }

          &:not(:last-child) {
            border-right: 1px solid #727272;
          }

          &:nth-child(1) {
            padding-right: 24px;
          }

          &:nth-child(2) {
            padding-left: 24px;
          }
        }
      }

      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;

        @media (min-width: 991px) {
          display: none;
        }
      }
    }
  }
  .verify-code {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
