@import 'assets/css/variables.scss';

.back-link {
  color: #ffffff;
  fill: #ffffff;
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;

  svg {
    width: 13px;
  }

  .back-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 100%;
    text-transform: capitalize;
    margin-left: 20px;

    @include media-up(sm) {
      font-size: 48px;
      line-height: 44px;
      margin-left: 32px;
    }
  }

  &:hover {
    color: darken($color: #ffffff, $amount: 20);
    fill: darken($color: #ffffff, $amount: 20);
  }
  &:active {
    color: #ffffff;
    fill: #ffffff;
  }
}
