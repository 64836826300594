.total-balance {
  display: none;
  align-items: center;
  justify-content: space-around;
  padding: 7px 12px;
  border: 1px solid #ffb905;
  background: #00512a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-right: 32px;

  @media (min-width: 991px) {
    display: flex;
  }

  .total-balance-info {
    margin-right: 32px;

    > p {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
      margin-bottom: 8px;
      text-transform: uppercase;
    }

    > span {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #ffffff;
      background: #002c2f;
      border-radius: 14px;
      border: none;
      height: 22px;
      width: 136px;
      padding-left: 12px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: #002c2f;
      border-radius: 14px;
      border: none;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #ffffff;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 22px;
    }

    .ant-select-arrow {
      font-size: 10px;
      color: #ffffff;
    }
  }

  .total-balance-btn {
    button {
      background: linear-gradient(77.47deg, #017b3f 8.98%, #02301a 90.79%);
      border-radius: 40px;
      padding: 8px 16px;
      height: 38px;
      display: flex;
      align-items: center;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
      cursor: pointer;

      svg {
        margin-right: 16px;
      }
    }
  }
}
