@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// @font-face {
//   font-family: 'VisbyRoundCF-Regular';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../fonts/Webfont/VisbyRoundCF-Regular.eot'); /*IE9 Compat Modes*/
//   src: local('VisbyRoundCF-Regular'), local('Regular'),
//     url('../fonts/Webfont/VisbyRoundCF-Regular.eot?#iefix') format('embedded-opentype') /*IE6-IE8*/,
//     url('../fonts/Webfont/VisbyRoundCF-Regular.woff2') format('woff2') /*Super Modern browsers*/,
//     url('../fonts/Webfont/VisbyRoundCF-Regular.woff') format('woff') /*Modern browsers*/;
// }
