@import 'assets/css/variables.scss';

$pText: #fff;
$pTextSelect: #000;
$pTextActive: #f6a92d;
$pBackground: transparent;

.ant-table-wrapper {
  .ant-table {
    .ant-table-header {
      table {
        border-collapse: collapse;
      }
    }
    background: $pBackground;
    .ant-table-body {
      overflow: hidden;
      @include media-up(lg) {
        overflow: auto !important;
      }
    }
    thead {
      height: 0;
      tr > th {
        color: #fff;
        font-weight: 700;
        line-height: 24px;
        background: transparent;
        border: unset;
        font-size: 16px;
        @include media-up(lg) {
          font-size: 18px;
        }
      }
    }
    tbody {
      tr {
        &:hover {
          td {
            background: transparent !important;
          }
        }
        td {
          color: $pText;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          background: transparent;
          border: unset;
          padding: 8px 16px;
          text-align: left;
        }
      }
    }
    .ant-empty-description {
      color: $pText;
    }
  }
  ul.ant-pagination {
    color: $pText;
    li,
    button {
      border-radius: 5px;
    }

    .ant-pagination-prev button,
    .ant-pagination-next button {
      color: $pText;
      background: transparent;
      &:hover {
        border: 1px solid $pTextActive;
      }
    }
    .ant-pagination-item-ellipsis {
      color: #333;
    }
    .ant-pagination-item {
      border: 1px solid $pText;
      background: transparent;
      @include media-down(md) {
        margin-left: 5px;
      }

      a {
        color: $pText;
      }
    }
    .ant-pagination-item-active {
      background: $pBackground;
      border: 1px solid $pTextActive;
      a {
        color: $pTextActive;
      }
    }

    .ant-pagination-options {
      .ant-select-selector {
        color: $pText;
        padding: 0 16px;
        background-color: transparent;
        border-radius: 5px;
        overflow: hidden;
      }
      // Ant select hover color
      .ant-select-selector:hover,
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: $pTextActive;
      }

      .ant-select-item {
        color: $pTextSelect;
      }
      .ant-select-item-option-active,
      .ant-select-item-option-selected {
        color: $pTextActive;
      }
    }
  }
}
