.wrapper-table-account-activity {
  background: #00271a;
  border-radius: 30px;
  padding: 32px;

  .table-account-activity {
    &.ant-table-wrapper .ant-table thead tr > th {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #798c6e;
      padding-top: 0;
      border-bottom: 1px solid #006917;
    }

    &.ant-table-wrapper .ant-table tbody tr td {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &.ant-table-wrapper .ant-table tbody tr:nth-child(2) td {
      padding-top: 24px;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
      padding-left: 0;
      padding-bottom: 16px;
      padding-right: 16px;
    }

    &.ant-table-wrapper .ant-table tbody tr td:first-child {
      padding-left: 0;
    }

    .action-logout {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      background: linear-gradient(89.87deg, #3f9400 0.09%, #1ec203 95.39%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      cursor: pointer;
    }

    .ant-table-thead th.ant-table-column-has-sorters:hover {
      background: transparent;
    }

    .ant-table-column-sorters {
      padding: 0;

      .ant-table-column-sorter-full {
        display: none;
      }
    }
  }
}
