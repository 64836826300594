@import 'assets/css/variables.scss';

.card-balance {
  position: relative;
  padding: 16px;
  background: #003b2d;
  border-radius: 30px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  overflow: hidden;

  @media (min-width: 991px) {
    padding: 40px 20px;
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0) translate(159%, -100%);
    width: 80px;

    @media (min-width: 991px) {
      width: auto;
    }
  }

  &::before {
    content: '';
    background: linear-gradient(71.27deg, rgba(66, 253, 36, 0.49) 14.9%, rgba(19, 255, 241, 0.4704) 94.83%);
    position: absolute;
    z-index: 0;
    width: 167px;
    height: 126px;
    border-radius: 50%;
    top: 0%;
    right: 0%;
    transform: translate(10%, -25%);
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    transform: matrix(-1, 0, 0, 1, 0, 0);

    .balance-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;

      @media (min-width: 991px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .card-percent {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #11cabe;
      svg {
        height: 16px;
        margin-right: 5px;
      }

      &[data-up='true'] {
        color: $success;
        fill: $success;
      }
      &[data-up='false'] {
        color: $warning;
        fill: $warning;
      }
    }
  }
  .card-content {
    padding-top: 10px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: flex;

    @media (min-width: 991px) {
      padding-top: 20px;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #00dd31;

      &:first-child {
        margin-right: 10px;
      }

      @media (min-width: 991px) {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
  .card-button {
    margin-top: 26px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 15px;

    @media (min-width: 991px) {
      margin-top: 40px;
    }

    .card-button-item {
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 150%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.25);
      border: 1px solid #ffffff;
      border-radius: 30px;
      color: #ffffff;
      padding: 5px 10px;
      cursor: pointer;
      transition: 0.2s;

      svg {
        width: 10px;
        margin-left: 5px;

        path {
          fill: #fff;
        }
      }

      &:hover {
        color: #000000;
        background: #ffffff;

        svg {
          path {
            fill: #000000;
          }
        }
      }

      @media (min-width: 370px) {
        font-size: 12px;
      }

      @media (min-width: 991px) {
        padding: 8px 16px;
        font-size: 16px;
        line-height: 20px;

        svg {
          width: 16px;
          margin-left: 8px;
        }
      }
    }
  }

  .card-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 28px;
    transform: matrix(-1, 0, 0, 1, 0, 0);

    @media (min-width: 991px) {
      margin-bottom: 44px;
      font-size: 24px;
      line-height: 29px;
    }

    span {
      color: #ffe600;
    }
  }
}
