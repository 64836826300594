@import 'assets/css/variables.scss';

.wrapper-buy-lottery {
  max-width: 990px;
  padding: 32px 15px 0 15px;
  margin: 0 auto;

  @media (min-width: 991px) {
    padding: 80px 15px;
  }

  .buy-ticket-container-banner {
    position: relative;
    transform-origin: 0 0.001%;

    img {
      width: 100%;
    }

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-75%, -24%);
      width: 55%;
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 76px;
      text-align: center;
      background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .buy-lottery-banner {
    margin-bottom: 32px;

    img {
      width: 100%;
    }

    @media (min-width: 991px) {
      margin-bottom: 64px;
    }
  }

  .buy-lottery-ticket {
    margin-top: 32px;

    @media (min-width: 991px) {
      margin-top: 64px;
    }

    > p {
      align-items: center;
      margin-bottom: 28px;
      display: none;

      a {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #ffffff;
      }

      @media (min-width: 991px) {
        display: flex;
      }

      svg {
        width: 13px;
        margin-right: 24px;
      }
    }

    .buy-lottery-ticket-content {
      padding: 16px 13.5px;
      background: #052c22;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;

      @media (min-width: 991px) {
        padding: 32px 34.5px;
      }

      .buy-lottery-ticket-item {
        &:not(:last-child) {
          margin-bottom: 24px;

          @media (min-width: 991px) {
            margin-bottom: 32px;
          }
        }

        > p {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #e9e9e9;
          margin-bottom: 16px;

          &.note {
            font-weight: 500;
            font-size: 10px;
            line-height: 150%;
            letter-spacing: -0.011em;
            color: #abd600;
            margin-top: 8px;
          }

          @media (min-width: 991px) {
            font-size: 20px;
            line-height: 24px;

            &.note {
              font-size: 14px;
            }
          }
        }

        .buy-lottery-ticket-item-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 12px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 15px;

          @media (min-width: 991px) {
            padding: 16px 32px;
          }

          .item-content-left {
            width: 50%;

            .box-select {
              .ant-input-group.ant-input-group-compact {
                width: 100%;
                max-width: 100%;

                .ant-select-selector {
                  padding-left: 0;
                  border: none;
                  outline: none;
                  box-shadow: none;

                  .ant-select-selection-item {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 120%;
                    color: #ffffff;

                    .select-label {
                      &::after {
                        content: '';
                        margin-left: 8px;
                        width: 0;
                        height: 0;
                        border-left: 4.5px solid transparent;
                        border-right: 4.5px solid transparent;
                        border-top: 7px solid #ffffff;
                      }
                    }

                    @media (min-width: 991px) {
                      font-size: 16px;
                      line-height: 20px;

                      .select-label {
                        &::after {
                          content: '';
                          margin-left: 16px;
                          border-left: 5.5px solid transparent;
                          border-right: 5.5px solid transparent;
                          border-top: 9px solid #ffffff;
                        }
                      }
                    }
                  }
                }
              }

              .box-form-control .ant-select .ant-select-arrow {
                display: none;
              }
            }

            .content-box-input {
              padding-right: 24px;

              input {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.011em;
                color: #ecd400;
                background-color: transparent;
                border: 0.5px solid rgba(167, 255, 101, 0.5);
                border-radius: 8px;
                max-width: 112px;
                padding: 0.5px 10px;
                outline: none;
                box-shadow: none;
                margin-bottom: 10px;

                @media (min-width: 991px) {
                  max-width: 130px;
                  font-size: 18px;
                  margin-bottom: 16px;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                &[type='number'] {
                  -moz-appearance: textfield;
                }
              }

              .choose-amount {
                display: flex;
                align-items: center;
                list-style-type: none;
                margin-bottom: 0;

                li {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 32px;
                  height: 32px;
                  background: rgba(255, 255, 255, 0.2);
                  border-radius: 6px;
                  cursor: pointer;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 150%;
                  letter-spacing: -0.011em;
                  color: #ffffff;

                  &.active {
                    background: rgba(0, 0, 0, 0.2);
                    border: 1px solid rgba(255, 255, 255, 0.2);
                  }

                  &:not(:last-child) {
                    margin-right: 8px;
                  }

                  @media (min-width: 991px) {
                    font-size: 14px;
                    width: 34px;
                    height: 34px;

                    &:not(:last-child) {
                      margin-right: 14px;
                    }
                  }
                }
              }
            }
          }

          .item-content-right {
            width: 50%;
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            padding: 2px 0 2px 24px;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #ffffff;
              margin-bottom: 8px;

              &:last-child {
                font-weight: 600;
                margin-bottom: 0;
              }
            }

            @media (min-width: 991px) {
              padding-left: 45px;

              p {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }

        .buy-lottery-ticket-action {
          button {
            cursor: pointer;
            height: 27px;
            border-radius: 15px;
            font-size: 12px;
            line-height: 15px;
            padding: 0;

            @media (min-width: 991px) {
              height: 52px;
              font-size: 16px;
              line-height: 20px;
            }
          }

          .buy-lottery-ticket-auto {
            width: 100%;
            max-width: 100%;

            > div {
              p {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                background: linear-gradient(89.87deg, #a7ff65 0.09%, #2dff0b 95.39%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;

                @media (min-width: 991px) {
                  font-size: 16px;
                  line-height: 20px;
                }
              }
            }
          }

          .buy-lottery-ticket-manual {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #ffffff;
            width: 100%;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 15px;
            background: transparent;

            @media (min-width: 991px) {
              font-size: 16px;
              line-height: 20px;
            }
          }

          .wrapper-choose-number {
            @media (min-width: 991px) {
              margin-bottom: 20px;
            }

            .wrapper-choose-number-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;

              @media (min-width: 991px) {
                margin-bottom: 24px;
              }

              p {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #ffffff;

                @media (min-width: 991px) {
                  font-size: 16px;
                  line-height: 20px;
                }
              }

              button {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                color: #ffffff;
                padding: 6.5px 11.5px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 10px;
                border: none;
                height: 33.67px;

                @media (min-width: 991px) {
                  font-size: 16px;
                }
              }
            }

            .wrapper-choose-number-body {
              ul {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                list-style: none;
                margin-bottom: 0;

                li:nth-child(5n + 1):nth-last-child(-n + 5),
                li:nth-child(5n + 1):nth-last-child(-n + 5) ~ li {
                  margin-bottom: 0;
                }

                @media (min-width: 991px) {
                  li:nth-child(11n + 1):nth-last-child(-n + 11),
                  li:nth-child(11n + 1):nth-last-child(-n + 11) ~ li {
                    margin-bottom: 0;
                  }
                }

                li {
                  width: 20%;
                  margin-bottom: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  @media (min-width: 991px) {
                    display: block;
                    width: 9.09090909091%;
                  }

                  p {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #5b561c;
                    border-radius: 50%;
                    height: 30px;
                    width: 30px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    cursor: pointer;
                    margin: auto;

                    span {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 100%;
                      height: 100%;
                      background: linear-gradient(89.87deg, rgba(167, 255, 101, 0.5) 0.09%, rgba(45, 255, 11, 0.5) 95.39%);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                      background-clip: text;
                      text-fill-color: transparent;
                    }

                    @media (min-width: 991px) {
                      font-size: 16px;

                      &:hover {
                        background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
                        color: #000000;
                        -webkit-background-clip: unset;
                        -webkit-text-fill-color: unset;
                        background-clip: unset;
                        text-fill-color: unset;
                      }
                    }

                    &.active {
                      background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);

                      span {
                        background: transparent;
                        -webkit-background-clip: unset;
                        -webkit-text-fill-color: unset;
                        background-clip: unset;
                        text-fill-color: unset;
                        color: #000000;
                      }
                    }
                  }
                }
              }
            }
          }

          .wrap-list-number {
            width: fit-content;
            @media (max-width: 767px) {
              margin: 0 auto;
            }
            .my-ticket {
              width: 100%;
              padding: 4px 10px;
              margin-bottom: 0.5rem;
              cursor: pointer;

              display: flex;
              align-items: center;
              list-style-type: none;

              &.active,
              &:hover {
                background: #6d6d6d;
                border-radius: 12px;
              }

              li {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                color: #000000;
                font-style: normal;
                font-weight: 600;
                background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);

                &:not(:last-child) {
                  margin-right: 16px;
                }
              }
            }
          }

          .button-submit {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
