@import 'assets/css/variables.scss';

.custom-chart-svg {
  .draw-pie-chart-item {
    animation: circle-chart-fill 2s reverse;
    cursor: pointer;
    transition: 0.2s;
  }

  .percent-item {
    font-style: normal;
    font-weight: 600;
    font-size: 4px;
    fill: #00dd31;
  }

  .wrapper-sub {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;

    @media (min-width: 340px) {
      padding-left: 15px;
    }

    @media (min-width: 1200px) {
      padding-left: 30px;
    }

    .wrapper-sub-item {
      display: flex;
      width: 45%;
      text-align: right;
      margin-bottom: 32px;

      @media (min-width: 1200px) {
        margin-bottom: 49px;
      }

      .header-sub-item-currency {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;

        svg {
          transition: 1s;
          margin-right: 3.37px;

          &.active {
            transform: rotate(360deg);
          }
        }

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 20px;

          svg {
            margin-right: 5px;
          }
        }
      }

      .header-sub-item-percent {
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 10px;
        color: #c8c8c8;

        @media (min-width: 991px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}
