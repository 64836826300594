.hisotry-recent-transaction-table-desktop {
  overflow: hidden;
  .ant-table {
    background: transparent;
    overflow: hidden;
  }

  .ant-table-container {
    min-width: 500px;
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: transparent;
  }

  .ant-table-thead > tr > th {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #fff;
    background: transparent;
    text-align: center;
    padding: 16px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: rgba($color: #ffffff, $alpha: 0.02);
  }

  .ant-table-thead > tr > th {
    text-align: center;
    padding: 0;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    text-align: left;
    padding-left: 16px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody > tr > td:last-child {
    padding-right: 0;
    text-align: center;
  }

  .ant-table-tbody > tr > td:first-child {
    padding-left: 0;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &.recent-transaction-table-time {
      text-align: left;
      color: #a5adcf;
    }

    &.recent-transaction-table-action {
      color: #ffffff;
    }

    &.recent-transaction-table-network {
      font-weight: 600;
      color: #ffffff;
    }

    &.recent-transaction-table-currency {
      color: #fcfcfd;
    }

    &.recent-transaction-table-amount {
      color: #ffffff;
    }
  }

  .recent-transaction-table-view {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #00f4a3;
  }

  .recent-transaction-table-status {
    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;

      border-radius: 3px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &[data-status='CREATED'] {
        color: #ef8087;
      }
      &[data-status='COMPLETED'] {
        color: #389201;
      }
    }
  }
}
