.box-show-withdraw-address-text {
  align-items: center;
  margin-bottom: 16px;

  > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: #fff;
    margin-bottom: 0;
    white-space: break-spaces;
    word-break: break-word;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
  .wrapper-wd-input-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    // input{
    //   width: 100%;
    //   background: #0000;
    // }
    .address-action {
      display: flex;
      position: relative;
      margin-left: 16px;

      @media (min-width: 991px) {
        margin-left: 26px;
      }

      img {
        cursor: pointer;
        max-width: 35px;

        @media (min-width: 991px) {
          max-width: 100%;
        }

        &:nth-child(2) {
          margin-left: 16px;
        }
      }

      .tooltip {
        position: absolute;
        content: '';
        background: rgb(0, 0, 0);
        width: fit-content;
        height: fit-content;
        padding: 10px;
        top: 50%;
        left: 50%;
        border-radius: 20px;
        transform: translate(-50%, 55%);
      }
      .tooltip-qr {
        position: absolute;
        content: '';
        background: rgb(0, 0, 0);
        width: fit-content;
        height: fit-content;
        padding: 10px;
        top: 50%;
        left: 50%;
        border-radius: 20px;
        transform: translate(-20%, 55%);
      }
    }
  }
}
