@import 'assets/css/variables.scss';

.wrapper-form-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 768px) {
    align-items: center;
    backdrop-filter: unset;
    background: unset;
    // padding-top: 8%;
  }

  h2 {
    color: #ffffff;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 40px;
  }
  .form-login {
    padding: 15.26px 12.8px;

    @include media-up(md) {
      max-width: 687px;
      padding: 60px;
    }

    @include media-up(lg) {
      padding: 32px 32px 45px 32px;
    }

    .ant-form-item {
      margin-bottom: 16px;

      .ant-form-item-explain {
        margin-bottom: -15px;
      }

      @media (min-width: 991px) {
        margin-bottom: 32px;
      }
    }

    .form-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #ffffff;
      margin-bottom: 0;

      @media (min-width: 991px) {
        font-size: 20px;
        margin-bottom: 8px;
      }
    }

    .wrapper-label {
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        background: linear-gradient(71.27deg, rgba(66, 253, 36, 0.8) 14.9%, rgba(19, 255, 241, 0.768) 94.83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        &:hover {
          text-decoration: underline;
        }

        @media (min-width: 991px) {
          font-size: 16px;
        }
      }
    }

    .form-action {
      width: 100%;
      max-width: 187px;
      margin: 0 auto;

      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      @include media-up(sm) {
        max-width: 287px;
        margin: 0 auto;
      }
    }

    .form-links {
      display: flex;
      justify-content: center;
      align-items: center;

      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      background: linear-gradient(71.27deg, rgba(66, 253, 36, 0.8) 14.9%, rgba(19, 255, 241, 0.768) 94.83%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @media (min-width: 991px) {
        font-size: 16px;
      }

      a {
        margin-left: 5px;

        @include media-up(sm) {
          font-size: 16px;
        }
      }
    }

    .header-auth-card {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      @media (min-width: 991px) {
        margin-bottom: 32px;
      }

      div {
        display: flex;
        align-items: center;

        a {
          display: flex;
          align-items: center;
          height: 44px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #fdfdfd;

          @media (min-width: 991px) {
            font-size: 24px;
            height: 56px;
          }

          &.active {
            span {
              font-style: normal;
              font-weight: 700;
              background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }

          &:not(:last-child) {
            border-right: 1px solid #727272;
          }

          &:nth-child(1) {
            padding-right: 24px;
          }

          &:nth-child(2) {
            padding-left: 24px;
          }
        }
      }

      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;

        @media (min-width: 991px) {
          display: none;
        }
      }
    }
  }
}
