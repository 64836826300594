@import 'assets/css/variables.scss';

.wrapper-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &[data-size='small'] {
    input {
      padding: 15px 20px 15px 20px;
      height: 18px;

      @include media-up(sm) {
        height: 26px;
      }
    }
  }
  &[data-size='standard'] {
    input {
      padding: 15px 20px 15px 20px;
      height: 42px;

      @include media-up(sm) {
        height: 54px;
      }
    }
  }
  &[data-size='larger'] {
    input {
      padding: 15px 20px 15px 20px;
      height: 56px;

      @include media-up(sm) {
        height: 66px;
      }
    }
  }

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #fdfdfd;
    width: 100%;
    height: 100%;
    background: transparent !important;
    outline: unset !important;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
    border-radius: 10px;

    @include media-up(md) {
      font-size: 16px;
    }

    &::placeholder {
      color: #a5adcf;
      @include media-up(md) {
        font-size: 16px;
      }
    }

    &:hover,
    &:valid {
      outline: unset;
    }

    &:hover,
    &:focus {
      border: 1px solid #55ee2c;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  .ant-input-affix-wrapper {
    padding: 0;
    background-color: transparent !important;
    border: none;

    .ant-input-suffix {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);

      svg {
        path {
          fill: #ffffff;
        }
      }
    }

    .ant-input {
      border: 1px solid #ffffff;

      &:hover {
        border: 1px solid #55ee2c;
      }
    }
  }
}
