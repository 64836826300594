.modal-2fa {
  max-width: 100vw;
  margin-bottom: 70px;

  .ant-modal-close {
    top: 47px;
    right: 55px;

    .ant-modal-close-x {
      height: 20px;
      width: 20px;

      svg {
        width: 10px;
      }
    }

    @media (min-width: 991px) {
      top: 50px;
      right: 55px;

      .ant-modal-close-x {
        height: 30px;
        width: 30px;

        svg {
          width: 17px;
        }
      }
    }
  }

  .sub-model {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 24px;

    @media (min-width: 991px) {
      margin-bottom: 32px;
      font-size: 16px;
    }

    span {
      background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .note-model {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 24px;

    @media (min-width: 991px) {
      margin-bottom: 32px;
      font-size: 16px;
    }
  }

  .modal-2fa-box-input {
    .modal-2fa-box-input-item {
      margin-bottom: 24px;

      @media (min-width: 991px) {
        margin-bottom: 32px;
      }

      input {
        border: none !important;
      }

      .copy-success {
        border-radius: 15px !important;
      }

      > p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        margin-bottom: 16px;
        text-align: left;
      }
    }
  }

  .wrapper-input {
    .ant-input-affix-wrapper {
      background: rgba(0, 0, 0, 0.1);
      border: 1px solid #364a2f;
      border-radius: 15px;
      padding: 16.5px 32px;

      input {
        height: auto;
        font-size: 14px;
        line-height: 150%;
      }
    }
  }

  .ant-modal-header {
    padding: 46px 54px 0 54px;
    margin-bottom: 24px;

    @media (min-width: 991px) {
      margin-bottom: 32px;
    }

    .modal-verify-cody-header {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;

      @media (min-width: 991px) {
        font-size: 32px;
        line-height: 39px;
      }
    }
  }
  .ant-modal-body {
    text-align: center;
    padding: 0 54px;
  }
  .ant-modal-footer {
    padding: 0;
    padding-bottom: 30px;
  }
  .canvas-qrcode {
    width: 100%;
    height: 100%;
    max-width: 204px;
    max-height: 204px;
    margin: 0 auto 20px;
    border: 2px solid white;
  }

  .text-note {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 20px 0;
    max-width: 300px;
    margin: 0 auto;
  }
}
