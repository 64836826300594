@import 'assets/css/variables.scss';

.form-change-password {
  .ant-modal-content {
    box-shadow: none;
    max-width: 100%;
    padding: 32px 30px;
    background: #001f14;
    border-radius: 15px;

    @media (min-width: 991px) {
      padding: 48px 64px;
    }

    .ant-modal-header {
      padding: 0;
    }

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;

      .modal-change-password-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;

        .modal-change-password-title {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;
          color: #ffffff;

          @media (min-width: 991px) {
            font-size: 32px;
            line-height: 39px;
          }
        }

        .modal-change-password-close {
          width: 35px;
          cursor: pointer;
        }
      }

      .modal-change-password-content {
        .ant-form {
          label {
            span {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: #ffffff;

              @media (min-width: 991px) {
                font-size: 20px;
                line-height: 24px;
              }
            }
          }

          .ant-form-item {
            margin-bottom: 32px;

            &:last-child {
              margin-bottom: 0;
            }

            &:not(.verification-code) {
              .ant-form-item-control-input-content {
                input {
                  height: 40px;
                  width: 100%;
                  background: rgba(0, 0, 0, 0.1);
                  border: 1px solid #364a2f;
                  border-radius: 15px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 150%;
                  color: #ffffff;
                  padding: 19px 32px;

                  @media (min-width: 991px) {
                    height: 59px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }
}
