@import 'assets/css/variables.scss';

.verify-code {
  .verify-title {
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 32px;

    @include media-up(sm) {
      font-size: 28px;
    }
  }
  .verify-resend {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;

    > div {
      color: #fff;
    }
  }
  .verify-error {
    color: $warning;
    font-size: 13px;
    text-align: center;
    padding: 20px 10px;
    @include media-up(sm) {
      font-size: 16px;
    }
  }
  .verify-action {
    width: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    row-gap: 20px;
  }
}
