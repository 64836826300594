.wrapper-banner {
  padding: 0 0 16px 0;
  max-width: 990px;
  margin: auto;

  @media (min-width: 991px) {
    padding: 0 15px 48px 15px;
    margin-top: -35px;
  }
}

.warp-banner {
  position: relative;
  max-width: 990px;
  width: 100%;
  margin: auto;
  transform-origin: 0 0.001%;

  .warp-banner-content-game-id {
    position: absolute;
    top: 27%;
    left: 4%;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: #ffffff;

    span {
      color: #ccff00;
    }

    @media (min-width: 991px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  img {
    width: 100%;
  }

  .warp-banner-halo-1 {
    position: absolute;
    width: 222.04px;
    height: 222.04px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -93%);
    z-index: 1;
    background: radial-gradient(
      50.07% 49.93% at 50.2% 49.93%,
      #ffe391 0%,
      #ffd34e 9%,
      #956c25 20%,
      #6e4f1b 26%,
      #4c3613 32%,
      #30220c 38%,
      #1a1306 45%,
      #0b0802 53%,
      #020100 61%,
      #000000 74%
    );
    background-blend-mode: screen;
    mix-blend-mode: screen;
    pointer-events: none;
    animation: flashOnOff 6s infinite;
  }

  .warp-banner-halo-2 {
    position: absolute;
    width: 410.72px;
    height: 34.25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -339%);
    z-index: 1;
    background: radial-gradient(
      49.9% 48.2% at 50.62% 92.94%,
      #ffe391 0%,
      #ffd34e 9%,
      #956c25 20%,
      #6e4f1b 26%,
      #4c3613 32%,
      #30220c 38%,
      #1a1306 45%,
      #0b0802 53%,
      #020100 61%,
      #000000 74%
    );
    background-blend-mode: screen;
    mix-blend-mode: screen;
    pointer-events: none;
    animation: flashOnOff 4s infinite;
  }

  .warp-banner-halo-3 {
    position: absolute;
    width: 21.36px;
    height: 256.11px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -74%);
    z-index: 1;
    background: radial-gradient(
      48.19% 49.9% at -839.83% 45.39%,
      #ffe391 0%,
      #ffd34e 9%,
      #956c25 20%,
      #6e4f1b 26%,
      #4c3613 32%,
      #30220c 38%,
      #1a1306 45%,
      #0b0802 53%,
      #020100 61%,
      #000000 74%
    );
    background-blend-mode: screen;
    mix-blend-mode: screen;
    pointer-events: none;
    animation: flashOnOff 3s infinite;
  }

  .warp-banner-halo-circle {
    span {
      position: absolute;
      width: 53px;
      height: 46px;
      top: 50%;
      left: 50%;
      z-index: 1;
      background-blend-mode: screen;
      mix-blend-mode: screen;
      opacity: 0;

      &:last-child {
        background: radial-gradient(
          45.35% 55.98% at 48.14% 49.99%,
          #f5f5f5 0%,
          #c7c7c7 5%,
          #9c9c9c 9%,
          #777777 14%,
          #565656 20%,
          #3b3b3b 25%,
          #252525 32%,
          #141414 40%,
          #080808 49%,
          #010101 63%,
          #000000 100%
        );
      }
      &:first-child {
        background: radial-gradient(
          45.61% 55.48% at 43.96% 50%,
          #ffeb7a 0%,
          #f7e376 1%,
          #bdaf5a 13%,
          #8b8042 25%,
          #60582e 37%,
          #3d381d 49%,
          #221f10 62%,
          #0f0e07 74%,
          #030301 87%,
          #000000 100%
        );
      }
    }
  }

  .warp-banner-halo-circle-1 {
    span {
      transform: translate(-208%, -428%);
      animation: flashOnOff 2s infinite;
      animation-delay: 1s;
    }
  }
  .warp-banner-halo-circle-2 {
    span {
      transform: translate(-285%, -420%);
      animation: flashOnOff 2s infinite;
      animation-delay: 2s;
    }
  }
  .warp-banner-halo-circle-3 {
    span {
      transform: translate(-365%, -395%);
      animation: flashOnOff 2s infinite;
      animation-delay: 3s;
    }
  }
  .warp-banner-halo-circle-4 {
    span {
      transform: translate(-396%, -327%);
      animation: flashOnOff 2s infinite;
      animation-delay: 4s;
    }
  }
  .warp-banner-halo-circle-5 {
    span {
      transform: translate(-382%, -242%);
      animation: flashOnOff 2s infinite;
      animation-delay: 5s;
    }
  }
  .warp-banner-halo-circle-6 {
    span {
      transform: translate(-361%, -160%);
      animation: flashOnOff 2s infinite;
      animation-delay: 6s;
    }
  }
  .warp-banner-halo-circle-7 {
    span {
      transform: translate(-299%, -112%);
      animation: flashOnOff 2s infinite;
      animation-delay: 7s;
    }
  }
  .warp-banner-halo-circle-8 {
    span {
      transform: translate(-208%, -120%);
      animation: flashOnOff 2s infinite;
      animation-delay: 8s;
    }
  }
  .warp-banner-halo-circle-9 {
    span {
      transform: translate(-124%, -125%);
      animation: flashOnOff 2s infinite;
      animation-delay: 9s;
    }
  }
  .warp-banner-halo-circle-10 {
    span {
      transform: translate(-35%, -131%);
      animation: flashOnOff 2s infinite;
      animation-delay: 10s;
    }
  }
  .warp-banner-halo-circle-11 {
    span {
      transform: translate(55%, -126%);
      animation: flashOnOff 2s infinite;
      animation-delay: 9s;
    }
  }
  .warp-banner-halo-circle-12 {
    span {
      transform: translate(137%, -119%);
      animation: flashOnOff 2s infinite;
      animation-delay: 8s;
    }
  }
  .warp-banner-halo-circle-13 {
    span {
      transform: translate(213%, -115%);
      animation: flashOnOff 2s infinite;
      animation-delay: 7s;
    }
  }
  .warp-banner-halo-circle-14 {
    span {
      transform: translate(277%, -157%);
      animation: flashOnOff 2s infinite;
      animation-delay: 6s;
    }
  }
  .warp-banner-halo-circle-15 {
    span {
      transform: translate(292%, -215%);
      animation: flashOnOff 2s infinite;
      animation-delay: 5s;
    }
  }
  .warp-banner-halo-circle-16 {
    span {
      transform: translate(304%, -276%);
      animation: flashOnOff 2s infinite;
      animation-delay: 4s;
    }
  }
  .warp-banner-halo-circle-17 {
    span {
      transform: translate(305%, -358%);
      animation: flashOnOff 2s infinite;
      animation-delay: 3s;
    }
  }
  .warp-banner-halo-circle-18 {
    span {
      transform: translate(236%, -413%);
      animation: flashOnOff 2s infinite;
      animation-delay: 2s;
    }
  }
  .warp-banner-halo-circle-19 {
    span {
      transform: translate(158%, -423%);
      animation: flashOnOff 2s infinite;
      animation-delay: 1s;
    }
  }

  .warp-banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
    width: 50%;

    .warp-banner-wrapper-content {
      transform: scale(1.3) translate(0, 13%);

      @media (min-width: 768px) {
        transform: translate(0, 8%);
      }
    }

    .warp-banner-content-money {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -151%);
      width: 446px;

      img {
        width: 100%;
      }

      > span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -1%);
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 53px;
        text-align: center;
        color: #ffb905;
        text-shadow: 4px 4px 4px #032612;
      }
    }

    .warp-banner-content-title {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
      text-align: center;
      margin-bottom: 10px;

      @media (min-width: 768px) {
        margin-bottom: 16px;
      }
    }

    .warp-banner-content-countdown {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        margin-bottom: 32px;
      }

      .banner-countdown-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #1c362d;
        box-shadow: 2px 4px 1px #010400;
        border-radius: 10px;
        border: 1px solid #a3ff62;
        min-width: 57px;
        min-height: 57px;

        &:not(:last-child) {
          margin-right: 41px;
        }

        span {
          &:first-child {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          &:nth-child(2) {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #ffffff;
          }
        }

        &:not(:last-child) {
          &::after {
            content: ':';
            font-style: normal;
            font-weight: 600;
            font-size: 35px;
            line-height: 150%;
            text-align: center;
            color: #00ff66;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(24.55px, -50%);
          }
        }
      }
    }

    .banner-button {
      text-align: center;
      margin-bottom: 12px;

      @media (min-width: 768px) {
        margin-bottom: 28px;
      }

      button {
        background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
        box-shadow: 3px 4px 7px rgba(200, 171, 36, 0.7);
        border-radius: 50px;
        min-width: 111px;
        min-height: 43px;
        padding: 8px 36px;
        border: none;
        cursor: pointer;

        &:disabled {
          background: #cccccc;
          box-shadow: 3px 4px 7px #cccccc;
        }

        p {
          font-style: normal;
          text-align: center;
          color: #021d00;

          &:nth-child(1) {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
          }

          &:nth-child(2) {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }

    .warp-banner-content-note {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.011em;
      color: #ffffff;
      text-align: center;

      span {
        font-weight: 700;
        color: #a6cd0a;
      }
    }

    .warp-banner-content-rules {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(88%, 206%);
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: #ffe600;
      cursor: pointer;
      width: 200px;
      text-align: center;

      @media (min-width: 768px) {
        transform: translate(142.6%, 350%);
      }
    }
  }
}
