.warp-how-to-play {
  position: relative;
  max-width: 990px;
  padding: 16px 15px 0 15px;
  width: 100%;
  margin: auto;

  @media (min-width: 991px) {
    padding: 30px 15px 75px 15px;
  }

  > p {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    background: linear-gradient(69deg, #ebb700, #ebfbd0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 48px;

    @media (min-width: 991px) {
      padding-left: 30px;
      font-size: 32px;
      line-height: 39px;
    }
  }

  .warp-how-to-play-item {
    margin-bottom: 48px;

    .how-to-play-item {
      text-align: center;

      img {
        width: 60%;
        margin-bottom: 12px;

        &.how-to-play-item-pc {
          display: none;

          @media (min-width: 768px) {
            display: block;
          }
        }

        &.how-to-play-item-mb {
          width: 29%;

          @media (min-width: 768px) {
            display: none;
          }
        }
      }

      @media (min-width: 576px) {
        img {
          width: 100%;
        }
      }

      p {
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.011em;

        &:nth-child(1) {
          font-weight: 600;
          background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        &:nth-child(2) {
          font-weight: 400;
          color: #ffffff;
          font-size: 12px;
        }

        @media (min-width: 991px) {
          &:nth-child(1),
          &:nth-child(2) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .warp-how-to-play-note {
    padding: 16px 12px;
    background: #0d2d25;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    @media (min-width: 991px) {
      padding: 16px 32px;
    }

    img {
      width: 70%;
      margin-bottom: 24px;

      @media (min-width: 991px) {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .warp-how-to-play-note-content {
      > p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.011em;
        background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (min-width: 991px) {
          font-size: 20px;
        }
      }

      ul {
        padding-left: 16px;

        @media (min-width: 991px) {
          padding-left: 30px;
        }

        li {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          letter-spacing: -0.011em;
          color: #ffffff;

          span {
            background: linear-gradient(69deg, #ebb700 2.33%, #ebfbd0 113.29%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          @media (min-width: 991px) {
            font-size: 16px;
          }
        }
      }
    }

    table {
      width: 100%;
      margin-top: 8px;

      tr {
        &:first-child {
          td {
            font-weight: bold;
          }
        }

        td {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          letter-spacing: -0.011em;
          color: #ffffff;
          padding: 4px 15px;

          &.active {
            background: #385148;
          }

          @media (min-width: 991px) {
            font-size: 16px;
          }
        }
      }
    }

    .wrap-table {
      width: 100%;
      overflow-x: auto;
      margin-bottom: 32px;
      padding-bottom: 16px;

      &::-webkit-scrollbar {
        width: 1px;
        height: 5px;
      }

      table {
        &.wrap-table-mb {
          tr {
            &:first-child {
              td {
                padding-bottom: 4px;

                &::before {
                  height: 30px;
                }
              }
            }

            td {
              border-bottom: 2px solid #0d2d25;
            }
          }

          @media (min-width: 768px) {
            display: none;
          }
        }

        &.wrap-table-pc {
          display: none;

          @media (min-width: 768px) {
            display: block;
          }
        }

        tr {
          &:first-child {
            td {
              padding-bottom: 16px;
            }
          }

          td {
            font-size: 12px;
            padding: 4px 4px;
            text-align: center;
          }
        }
      }
    }
  }
}
