.my-ticket-container {
  max-width: 1000px;
  padding: 32px 15px 0 15px;
  margin: 0 auto;

  .my-ticket-container-banner {
    position: relative;
    transform-origin: 0 0.001%;

    img {
      width: 100%;
    }

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-75%, -24%);
      width: 55%;
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 76px;
      text-align: center;
      background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}
