@import 'assets/css/variables.scss';

.wallet-list-container {
  @include media-up(lg) {
    padding-top: 34px;
  }
  .navigation-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .navigation-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include media-up(md) {
        width: 65%;
      }

      .navigation-right {
        .ant-input-search .ant-input {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          height: 35px;
          border-radius: 10px;
        }
      }

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        margin-bottom: 0;

        @include media-up(lg) {
          font-size: 32px;
          line-height: 44px;
        }
      }

      .right-switch {
        display: flex;
        align-items: center;

        svg {
          margin-left: 16px;
          width: 35px;

          @media (min-width: 991px) {
            display: none;
          }
        }

        > div {
          display: flex;
          align-items: center;

          @include media-up(md) {
            margin-right: 32px;
          }
        }

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          color: #fff;
          width: max-content;

          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 16px;
          }
        }
        .ant-switch-handle {
          left: 4px;

          &::before {
            background: #00512a;
            width: 15px;
            height: 15px;
          }
        }
        .ant-switch {
          background: #23242b;
          max-width: 37px;
          width: 100%;
          border: 1px solid #ffb905;
          border-radius: 100px;
          margin-right: 8px;
        }
        .ant-switch-checked {
          background: #23242b;
          border: 1px solid #ffb905;
          .ant-switch-handle {
            left: calc(100% - 18px - 1px);
            &::before {
              background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .navigation-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @include media-up(md) {
        width: 35%;
        margin-top: 0px;
      }
      .ant-input-search {
        .ant-input {
          outline: none;
          color: white;
          height: 50px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 30px;
          padding-left: 56px;

          &:focus {
            outline: none;
          }
        }
        .ant-input-group-addon {
          position: absolute;
          top: 50%;
          left: 24px;
          transform: translateY(-50%);
          outline: none;
          border-left: none;
          background: transparent;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;

          button {
            height: 30px;
            border: none;
            background: transparent;
            border-radius: 0 16px 16px 0;

            .anticon-search {
              color: #fff;
              ::after {
                outline: none;
                box-shadow: none;
              }
            }
          }
          &:hover {
            border-color: none;
          }
        }
      }
      .filter-icons {
        cursor: pointer;
        min-width: 40px;
        min-height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #23242b;
        border: 1px solid #34384c;
        border-radius: 6px;
        svg {
          width: 17px;
        }
      }
    }
  }
  .navigation-header-2 {
    .ant-table-tbody {
      display: none;
    }
  }
  .create-wallet {
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-end;
    button {
      width: fit-content;
    }
  }
  .table-wallet-list-desktop {
    .ant-spin-container {
      table {
        border-collapse: collapse;
        table-layout: unset !important;
        .ant-table-header {
          overflow: hidden !important;

          .ant-table-cell {
            &:last-child {
              padding: 0;
            }
          }
        }
      }

      .ant-table {
        background: #00271a;
        border-radius: 10px;
        padding: 32px;
      }
    }
    .coin-data {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      img {
        width: 100%;
        max-width: 20px;
        margin-right: 10px;
        @include media-up(md) {
          max-width: 24px;
        }
      }

      .coin-data-label {
        div:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          margin-right: 14px;
          white-space: nowrap;
          @include media-up(md) {
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: #fff;
          }
        }
      }
    }
    .actions {
      white-space: nowrap;
      display: flex;
      align-items: center;

      button {
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        margin-right: 30px;
        background: unset;
        border: unset;
        cursor: pointer;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
          width: 16px;
          height: 18px;

          path {
            fill: rgba($color: #35fb59, $alpha: 1);
          }
        }

        &:hover:not(&[data-disable='true']) {
          // color: #fff;
        }
        &:active:not(&[data-disable='true']) {
          opacity: 0.7;
        }

        &[data-disable='true'] {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
    .ant-table-thead tr {
      display: table-row;
      .ant-table-cell {
        padding: 16px;
        white-space: nowrap;
        &:last-child {
          padding: 0;
        }
      }
      @include media-up(md) {
        display: table-row;
      }
    }
  }
  .wallet-list-mobile {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table {
          .ant-table-header {
            display: none;

            @media (min-width: 991px) {
              display: block;
            }

            .ant-table-cell {
              &:last-child {
                padding: 0;
              }
            }
          }
          .ant-table-body {
            overflow: hidden !important;
            table {
              border-collapse: none;
              .ant-table-cell-scrollbar {
                display: none;
              }
            }
          }
        }
      }
    }
    .coin-data {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      img {
        width: 100%;
        max-width: 20px;
        margin-right: 10px;
        @include media-up(md) {
          max-width: 24px;
        }
      }

      .coin-data-label {
        div:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          margin-right: 14px;
          white-space: nowrap;
          @include media-up(md) {
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: #fff;
          }
        }
      }
    }
    .actions {
      white-space: nowrap;

      .ant-dropdown-link {
        text-align: right;
        cursor: pointer;

        svg {
          fill: #00f4a3;
        }

        button {
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          margin-right: 30px;
          background: unset;
          border: unset;
          cursor: pointer;

          svg {
            fill: #00f4a3;
            margin-right: 8px;
          }

          &:hover:not(&[data-disable='true']) {
            color: #fff;
          }
          &:active:not(&[data-disable='true']) {
            opacity: 0.7;
          }

          &[data-disable='true'] {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }
    .ant-table-thead tr {
      display: table-row;
      .ant-table-cell {
        padding: 16px;
        white-space: nowrap;
      }
      .ant-table-cell-scrollbar {
        box-shadow: none;
      }
      @include media-up(md) {
        display: table-row;
      }
    }
  }
  .wallet-list-mobile {
    .wallet-item {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      border-radius: 10px;

      .coin-data {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
          width: 100%;
          max-width: 30px;
          margin-right: 10px;
        }

        .coin-data-label {
          div:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            @include media-up(md) {
              font-size: 20px;
            }
          }
        }
      }
      .wallet-item-right {
        display: flex;
        .balance {
          font-size: 18px;
          white-space: nowrap;
          overflow: hidden;

          max-width: 50vw;
          display: flex;
          align-items: center;

          > span {
            color: #a5adcf;
            font-size: 12px;
            padding-left: 4px;
          }
        }
        .actions {
          white-space: nowrap;
          cursor: pointer;
          margin-top: 10px;
          .modal-action {
            button {
              color: #ffffff;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              margin-right: 10px;
              background: unset;
              border: none;
              padding: 2px 6px;
              cursor: pointer;
              &:hover {
                color: #fff;
              }
              &:active {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }

  .ant-table-wrapper .ant-table thead tr > th {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #aaaaaa;
    padding-top: 0 !important;
    padding-bottom: 24px !important;
    border-bottom: 1px solid #aaaaaa;
  }

  .ant-table-wrapper .ant-table tbody tr td {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #ffffff;
    padding: 8px 0;

    @media (min-width: 991px) {
      padding: 18px 0;
    }
  }

  .ant-table-wrapper .ant-table tbody tr:nth-child(2) td {
    padding-top: 0;

    @media (min-width: 991px) {
      padding-top: 36px;
    }
  }
}

.ant-dropdown-placement-bottomRight {
  .ant-dropdown-menu {
    background: #23242b;
    border-radius: 5px 5px 5px 6px;
    padding: 10px 0;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 20px;
      top: -16px;
      right: 4px;
      border-bottom: 16px solid #23242b;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      z-index: 111;
    }
    .ant-dropdown-menu-item {
      &:hover {
        background: unset;
      }
      a {
        button {
          color: #fff;
          background: transparent;
          border: none;
          outline: none;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
