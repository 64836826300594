@import 'assets/css/variables';
$color-1: #ffffff;
$color-2: #fcfcfd;
$bg-1: rgba(249, 53, 76, 0.2);

.input-coppy-ref-wrapper {
  position: relative;
  .style-input-title {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  h3 {
    font-size: 16px;
    color: $color-1;
    margin-bottom: 0.1em;
    font-weight: 600;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
  input.ant-input {
    font-size: 14px;
    color: $color-2 !important;
    background: #0a0017;
    opacity: 0.7;
    cursor: pointer;
    border: 1px solid rgba($color: #ffffff, $alpha: 0.3) !important;

    @media (min-width: 768px) {
      font-size: 17px;
    }
  }
  input {
    height: 45px;
    padding: 15px !important;
    padding-right: 50px !important;
    cursor: pointer;

    @media (min-width: 991px) {
      height: 50px;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 5px;
    input {
      background: transparent;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-readonly,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:valid {
    border: unset;
    outline: unset;
    box-shadow: unset;
  }

  .wrapper-input {
    position: relative;
    cursor: pointer;
    span {
      color: #ffa143;
    }
  }
  .copy-success {
    position: absolute;
    z-index: 20;
    top: 0;
    color: red;
    background: #5b337e;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 10px !important;

    &:active {
      opacity: 0.9;
    }
  }
  .link {
    position: absolute;
    z-index: 22;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block;
  }
  .icon-fly {
    img {
      width: 13.5px;
    }
  }
  .not-allowed .wrapper-input,
  .not-allowed input,
  .not-allowed img {
    cursor: not-allowed !important;
  }

  svg {
    path {
      fill: none !important;
    }
  }
}
@media only screen and (max-width: 768px) {
  canvas {
    height: auto !important;
  }
  .address-input {
    max-width: 100%;
  }
}
.icon-copy {
  width: 20px;
  height: 20px;

  @media (min-width: 768px) {
    width: 24.75px;
    height: 24.75px;
  }
}
