.item-recent-transaction {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #34384c;
  padding-left: 13.5px;
  padding-right: 13.5px;

  div {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #ffffff;

      &:first-child {
        color: #798c6e;
        font-size: 12px;
        line-height: 150%;
      }
    }
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 10px;
    margin-bottom: 0;
    border-bottom: none;
  }

  > div {
    margin-bottom: 8px;
    p {
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &[data-status='COMPLETED'] {
        display: flex;
        align-items: center;
        text-align: center;
        min-width: 70px;
        background: rgba(52, 117, 59, 0.5);
        border-radius: 4px;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 150%;
          color: #04d100;
        }
      }
    }
    .ts-log-out {
      color: #00f4a3;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
    }
    &:first-child {
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #ffffff;

        &:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #798c6e;
          margin-bottom: 0;
        }
      }
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #30daff;
      }
    }

    &:last-child {
      a {
        display: block;

        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #00f4a3;
        margin-bottom: 16px;
      }
    }
  }
}
