.table-winner-list {
  position: relative;

  .winner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translate(-50%, 158%);
    width: 25px;

    @media (min-width: 991px) {
      transform: translate(-50%, 55%);
      width: auto;
    }
  }

  .ant-table-wrapper {
    margin-bottom: 30px;

    .ant-table {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
      }

      .ant-table-thead {
        background: #1e3631;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
      }

      .ant-table-tbody {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &::before {
          content: '@';
          display: block;
          line-height: 24px;
          text-indent: -99999px;
        }
      }

      thead {
        tr > th {
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 8px;
          line-height: 150%;
          color: #ffffff;
          padding-top: 8px;
          padding-bottom: 8px;

          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        > tr:first-child {
          th {
            &:first-child {
              text-align: left;
              border-radius: 7px 0 0 7px;
            }
            &:last-child {
              border-radius: 0 7px 7px 0;
              min-width: 75px;
            }
          }
        }
      }

      tbody {
        tr td {
          text-align: center;
          font-style: normal;
          font-style: normal;
          font-weight: 600;
          font-size: 8px;
          line-height: 150%;
          color: #a7ff65;
          padding: 5px 16px;

          a {
            color: #a7ff65;
          }

          &:first-child {
            font-weight: 500;
            font-size: 8px;
            line-height: 150%;
            color: #ffffff;
            text-align: left;
            width: 150px;
          }

          @media (min-width: 991px) {
            font-size: 12px;
            line-height: 68%;
            padding: 10px 16px;

            &:first-child {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        .ant-table-row {
          background: rgba(62, 62, 62, 0.4);

          &:first-child {
            background: #204826;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;

            td {
              padding-top: 5px;
              padding-bottom: 5px;

              @media (min-width: 991px) {
                padding-top: 18px;
                padding-bottom: 18px;
              }

              &:first-child {
                border-radius: 15px 0 0 15px;
              }

              &:last-child {
                border-radius: 0 15px 15px 0;
              }
            }
          }

          &:last-child {
            td {
              padding-bottom: 30px;

              &:first-child {
                border-radius: 0 0 0 15px;
              }

              &:last-child {
                border-radius: 0 0 15px 0;
              }
            }
          }

          &:nth-child(2) {
            td {
              padding-top: 4px;

              @media (min-width: 991px) {
                padding-top: 24px;
              }
            }
          }
        }
      }
    }
  }

  .ticket-current {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 6px;
      line-height: 150%;
      color: #000000;
      background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(.active) {
        background: linear-gradient(89.87deg, rgba(167, 255, 101, 0.5) 0.09%, rgba(45, 255, 11, 0.5) 95.39%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        border: 1px solid #5b561c;
      }

      &:not(:last-child) {
        margin-right: 3px;
      }

      @media (min-width: 991px) {
        font-size: 12px;
        line-height: 68%;
        width: 26px;
        height: 26px;
      }
    }
  }

  .ant-pagination {
    position: relative;
    padding-right: 50px;
    text-align: right;

    @media (min-width: 991px) {
      padding-right: 78px;
    }

    li {
      border: unset !important;

      &.ant-pagination-jump-next,
      &.ant-pagination-jump-prev {
        .ant-pagination-item-container {
          .ant-pagination-item-ellipsis {
            color: #aeaeae;
            transform: translateY(3px);
            font-size: 12px;
            line-height: 150%;

            @media (min-width: 991px) {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }

      &.ant-pagination-item {
        background-color: transparent;

        a {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 150%;
          color: #aeaeae;
        }

        @media (min-width: 991px) {
          a {
            font-size: 16px;
            line-height: 20px;
          }
        }

        &.ant-pagination-item-active {
          a {
            font-style: normal;
            font-weight: 600;
            background: linear-gradient(89.87deg, #a7ff65 0.09%, #2dff0b 95.39%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }

      button {
        border: unset !important;
      }

      &:first-child,
      &:last-child {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        button {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background-color: transparent;

          span {
            svg {
              display: none;
            }
          }
        }
      }

      &:first-child {
        right: 25px;
        margin-right: 0;

        button {
          align-items: unset;

          span {
            width: 7px;
            height: 7px;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: 10px solid #c0ffd2;
          }
        }

        @media (min-width: 991px) {
          right: 39px;
        }
      }

      &:last-child {
        button {
          align-items: unset;

          span {
            width: 7px;
            height: 7px;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 10px solid #c0ffd2;
          }
        }
      }

      @media (min-width: 991px) {
        &:first-child {
          button {
            span {
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 10px solid #c0ffd2;
            }
          }
        }

        &:last-child {
          button {
            span {
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 10px solid #c0ffd2;
            }
          }
        }
      }
    }
  }
}
