@import './variables.scss';
@import './font-face.scss';
@import './animation.scss';
@import './resets.scss';
@import './components.scss';
@import './global-class.scss';

* {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
*::after,
*::before {
  box-sizing: border-box;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 1px;
}
::-webkit-scrollbar-thumb {
  background: #1c770d;
  border-radius: 3px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #5e6588;
  border-radius: 10px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: #ffffff;
  fill: #ffffff;
  // min-height: 550px;
}

#root {
  width: 100%;
  min-height: 100%;
  background-color: $secondary;
}
