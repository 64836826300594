@import 'assets/css/variables.scss';

.button-primary {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #004c43;
  box-shadow: 2px 5px 4px rgba(127, 255, 67, 0.25);
  border-radius: 50px;
  padding: 12px 36px;
  border: 1px solid #ffb905;
  cursor: pointer;

  svg {
    transform: translate(-9px, 4px);
    path {
      fill: #ffffff;
    }
  }

  &[disabled],
  &[data-loading='true'] {
    cursor: not-allowed;
    opacity: 0.7;
  }

  .ant-spin {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
