.modal-menu {
  position: fixed;
  width: 100%;
  height: calc(100%);
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #001710;
  padding: 160px 24px 32px 24px;
  overflow: auto;

  .modal-menu-box-input {
    position: relative;

    input {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      width: 100%;
      height: 40px;
      background: #003f24;
      border-radius: 10px;
      border: none;
      outline: none;
      box-shadow: none;
      padding: 13px 13px 13px 70px;
    }

    img,
    svg {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
    }
  }

  .modal-menu-box-menu {
    margin-bottom: 24px;
    padding-bottom: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid #07462d;
    }

    > p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #bcbcbc;
    }

    ul {
      list-style-type: none;
      padding: 0;
      padding-left: 12px;
      margin: 0;

      li {
        &:first-child {
          margin-top: 24px;
        }

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        a {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          color: #ffffff;

          img,
          svg {
            width: 32px;
            height: auto;
            margin-right: 16px;
          }
        }
      }
    }

    .menu-label {
      width: fit-content;
      position: relative;
      .menu-tag {
        font-size: 10px;
        font-weight: bold;
        line-height: 1;

        height: fit-content;
        padding: 6px 8px;
        // background: #00e9ff;
        border: 1px solid #00e9ff;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
          rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        border-radius: 30px;

        position: absolute;
        top: -8px;
        right: -5px;
        transform: translateX(100%);
        text-transform: uppercase;
        width: max-content;

        text-transform: uppercase;
      }
    }

    .menu-language {
      display: flex;
      align-items: center;
      padding: 24px 10px 8px 0px;
      border-top: 1px solid #07462d;

      .text-flag {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #ffffff;
      }
    }

    .ant-select {
      color: #fff;

      .ant-select-selector {
        background-color: transparent;
        border: none;
      }

      .ant-select-arrow {
        display: none;
      }
    }
  }

  .modal-menu-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
