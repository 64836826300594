.input-text-item {
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 8px;
  }

  input {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    height: 50px;
    border: 1px solid #34384c;
    box-sizing: border-box;
    border-radius: 8px;
    color: #ffffff;
    background: transparent;
    padding: 10px 16px;
    width: 100%;
    outline: none;
    color: #fff;
    &::placeholder {
      color: #a5adcf;
    }
  }

  @media (min-width: 991px) {
    // margin-bottom: 32px;
    width: 100%;

    input {
      font-size: 16px;
    }

    p {
      font-size: 20px;
      margin-bottom: 16px;
      line-height: 27px;
    }
  }
}
