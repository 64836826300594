.warp-page-security {
  background-color: transparent;
  padding: 20px 15px 0;
  max-width: 100%;
  margin-bottom: 64px;
  .page-security-heading {
    max-width: 1000px;
    margin: auto;
    padding: 0 25px;
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
    h2 {
      @media (min-width: 768px) {
        font-weight: 400;
        font-size: 48px;
        line-height: 61px;
        color: #ffffff;
      }
    }
  }
  .page-security-content {
    max-width: 1000px;
    margin: auto;
    .page-security-content-body {
      .security-item {
        margin-bottom: 32px;
        .btn-disable {
          border-color: rgba(250, 34, 86, 0.5);
          color: #fa2256;
        }

        &:last-child {
          margin-bottom: 0;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #a5adcf;

          &:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 27px;
            color: #ffffff;

            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
          }
        }
      }
    }

    @media (min-width: 991px) {
      .page-security-content-header {
        padding: 33px 40px;
        margin-bottom: 60px;

        .box-kyc {
          padding-bottom: 20px;
          margin-bottom: 20px;

          p {
            font-size: 16px;

            &:first-child {
              font-size: 24px;
            }
          }
        }
        .info-kyc {
          .info-item {
            span {
              font-size: 14px;
            }
          }
        }
      }

      .page-security-content-body {
        padding: 0 25px;
        .security-item {
          margin-bottom: 36px;

          p {
            font-size: 16px;
            line-height: 27px;

            &:first-child {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding: 42px 15px 0;
  }
}
