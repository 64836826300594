@import 'assets/css/variables.scss';

.button-submit {
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  padding: 16px 32px;
  background: linear-gradient(89.87deg, #5fdd00 0.09%, #008947 95.39%);
  border-radius: 10px;
  border: none;
  cursor: pointer;
  position: relative;

  @media (min-width: 991px) {
    font-size: 16px;
  }

  svg {
    transform: translate(-9px, 4px);
    path {
      fill: #ffffff;
    }
  }

  &[disabled],
  &[data-loading='true'] {
    cursor: not-allowed !important;
    opacity: 0.7;
  }

  .ant-spin {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
