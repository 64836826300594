.my-ticket-lottery-results {
  position: relative;
  max-width: 990px;
  padding: 16px 0;
  width: 100%;
  margin: auto;

  @media (min-width: 991px) {
    padding: 30px 0 48px 0;
  }

  .my-ticket-lottery-results-content {
    border-radius: 15px;

    @media (min-width: 991px) {
      background: #052c22;
      padding: 32px 30px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .my-ticket-lottery-results-content-action {
      margin-bottom: 27px;

      button {
        min-width: 136px;
        min-height: 30px;
        background: linear-gradient(89.87deg, #122701 0.09%, rgba(114, 255, 0, 0.2) 95.39%);
        border-radius: 20px;
        border: 1px solid #ffd144;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .my-ticket-lottery-results-content-info-result {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 16px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      padding: 8px;
      margin-bottom: 24px;

      @media (min-width: 991px) {
        margin-bottom: 32px;
        padding: 19px 16px;
        justify-content: space-between;
      }

      > div {
        width: 100%;

        @media (min-width: 768px) {
          &:first-child {
            width: 100%;
          }

          width: auto;
        }

        @media (min-width: 991px) {
          &:first-child {
            width: auto;
          }
        }

        @media (min-width: 1184px) {
          &:first-child {
            width: 100%;

            .action-choose-day {
              justify-content: flex-start;
            }
          }
        }

        @media (min-width: 1334px) {
          &:first-child {
            width: auto;

            .action-choose-day {
              justify-content: center;
            }
          }
        }
      }

      .content-info-result-left {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (min-width: 576px) {
          justify-content: space-around;
        }

        @media (min-width: 991px) {
          flex-direction: column;
        }

        .action-choose-day {
          display: flex;
          align-items: center;
          justify-content: center;

          .action-choose-day-gr-icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &:not(:last-child) {
            flex-direction: column;
            align-items: baseline;

            @media (min-width: 991px) {
              align-items: center;
            }

            p {
              margin-bottom: 8px;

              @media (min-width: 991px) {
                margin-bottom: 0;
              }
            }

            @media (min-width: 991px) {
              margin-bottom: 16px;
              flex-direction: row;
            }
          }

          &:last-child {
            align-items: flex-end;

            p {
              min-height: 25px;
              display: flex;
              align-items: center;
              color: #ffe500;
            }
          }

          p {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #ffffff;

            &:not(:last-child) {
              margin-right: 8px;

              @media (min-width: 991px) {
                margin-right: 16px;
              }
            }

            @media (min-width: 991px) {
              font-size: 16px;
              line-height: 20px;
            }
          }

          .warp-date-picker {
            position: relative;

            .ant-picker {
              background: transparent;
              border: 0.5px solid rgba(255, 255, 255, 0.5);
              border-radius: 5px;
              width: 119px;
              padding: 4px 21px 4px 8px;

              .ant-picker-input {
                > input {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 15px;
                  color: #ffffff;

                  @media (min-width: 991px) {
                    font-size: 16px;
                    line-height: 20px;
                  }
                }

                .ant-picker-suffix {
                  display: none;
                }
              }
            }

            svg {
              position: absolute;
              top: 50%;
              right: 8px;
              transform: translate(-50%, -50%);
              width: 5px;
              pointer-events: none;
            }
          }

          svg {
            cursor: pointer;

            &.prev-day-icon,
            &.next-day-icon {
              // width: 9px;
              width: 23px;
              padding: 7px;
            }

            &.prev-day-icon {
              // margin-right: 7px;
            }

            &.next-day-icon {
              // margin-left: 7px;
            }

            &.next-to-day-icon {
              // width: 14px;
              width: 22px;
              // margin-left: 4px;
              padding: 4px;
            }

            @media (min-width: 991px) {
              &.next-to-day-icon {
                // margin-left: 11px;
              }
            }
          }
        }
      }

      .content-info-result-center {
        width: 100%;

        @media (min-width: 991px) {
          width: auto;
        }

        .content-info-result-center-lucky-number {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 16px 32px;
          background-size: 100% 100%;

          @media (min-width: 991px) {
            justify-content: baseline;
            flex-direction: row;
            padding: 16px 32px;
            margin-bottom: 7px;
          }

          p {
            font-style: italic;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #fff200;
            margin-right: 0;
            margin-bottom: 16px;

            @media (min-width: 991px) {
              font-size: 16px;
              line-height: 20px;
              max-width: 70px;
              margin-bottom: 0;
              margin-right: 32px;
            }
          }

          .result-lucky-number {
            ul {
              display: flex;
              align-items: center;
              list-style: none;
              margin: 0;

              li {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 29px;
                height: 29px;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                color: #51381c;
                background-size: 100% 100%;

                &:not(:last-child) {
                  margin-right: 7.6px;
                }

                @media (min-width: 991px) {
                  width: 35px;
                  height: 35px;
                  font-size: 16px;
                }
              }
            }
          }
        }

        .content-info-result-center-lucky-note {
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-wrap: wrap;
          background-size: 100% 100%;
          padding-bottom: 16px;

          @media (min-width: 991px) {
            background-image: unset !important;
            padding-bottom: 0;
          }

          p {
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: -0.011em;
            padding: 0 7.5px;

            &:nth-child(1) {
              font-size: 12px;
              color: #ffffff;
            }

            a {
              font-size: 12px;
              text-decoration-line: underline;
              color: #00ff19;
              cursor: pointer;
            }

            @media (min-width: 991px) {
              &:nth-child(1) {
                font-size: 16px;
              }

              &:nth-child(2) {
                font-size: 14px;
              }
            }
          }
        }
      }

      .content-info-result-right {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;

        br {
          display: none;
        }

        p {
          font-style: normal;

          &:nth-child(1) {
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            text-align: center;
            letter-spacing: -0.011em;
            color: #ffffff;
          }

          &:nth-child(2) {
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.019em;
            color: #ffe600;
          }
        }

        @media (min-width: 991px) {
          display: block;
          align-items: unset;
          justify-content: unset;

          br {
            display: block;
          }

          p {
            &:nth-child(1) {
              font-size: 16px;
            }

            &:nth-child(2) {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
