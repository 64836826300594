@import 'assets/css/variables.scss';

.wrapper-form-newpass {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-height: 400px) {
    align-items: center;
  }

  h2 {
    color: #ffffff;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 40px;
  }
  .form-newpass {
    max-width: 580px;

    .form-action {
      width: 100%;
      max-width: 187px;
      margin: 16px auto;

      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      @include media-up(sm) {
        max-width: 287px;
        margin: 24px auto;
      }
    }

    .form-links {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      a {
        color: $link;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-left: 5px;

        @include media-up(sm) {
          font-size: 16px;
        }

        &:hover {
          opacity: 0.7;
        }
        &:active {
          opacity: 1;
        }
      }
    }
  }
}
