@import 'assets/css/variables.scss';

$pText: #fff;
$pTextSelect: #000;
$pTextActive: #f6a92d;
$pBackground: transparent;

.ant-pagination.custom-pagination {
  color: $pText;
  li,
  button {
    border-radius: 5px;
  }

  .ant-pagination-prev button,
  .ant-pagination-next button {
    color: $pText;
    background: transparent;
    &:hover {
      border: 1px solid $pTextActive;
    }
  }
  .ant-pagination-item-ellipsis {
    color: #333;
  }
  .ant-pagination-item {
    border: 1px solid $pText;
    background: transparent;
    @include media-down(md) {
      margin-left: 5px;
    }

    a {
      color: $pText;
    }
  }
  .ant-pagination-item-active {
    background: $pBackground;
    border: 1px solid $pTextActive;
    a {
      color: $pTextActive;
    }
  }

  .ant-pagination-options {
    .ant-select-selector {
      color: $pText;
      padding: 0 16px;
      background-color: transparent;
      border-radius: 5px;
      overflow: hidden;
    }
    // Ant select hover color
    .ant-select-selector:hover,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: $pTextActive;
    }

    .ant-select-item {
      color: $pTextSelect;
    }
    .ant-select-item-option-active,
    .ant-select-item-option-selected {
      color: $pTextActive;
    }
  }
}
