@import 'assets/css/variables.scss';

.footer-container {
  overflow: hidden;

  .footer-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 48px 24px 0px;
    @include media-up(md) {
      padding: 80px 24px 0px;
    }
  }

  .footer-des {
    font-size: 14px;
    @include media-up(md) {
      font-size: 18px;
    }
    color: #bfbfbf;
    font-weight: 400;
    line-height: 27px;
    margin-top: 15px;
    max-width: 350px;
  }

  .footer-logo {
    margin-right: 20px;
    cursor: pointer;
    img {
      width: 100%;
      max-width: 280px;
      object-fit: contain;
      margin-right: 10px;
    }
    span {
      color: #ffffff;

      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      white-space: nowrap;
    }
  }

  .socials-list {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    margin: 0 -16px;
    margin-top: 18px;

    a {
      display: block;
      margin: 0 16px;
    }
    img {
      width: auto;
      height: 20px;
      object-fit: contain;
    }
  }

  .footer-title {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 10px;
    @include media-up(768px) {
      font-size: 20px;
    }
  }
  .footer-sub-title {
    color: #bfbfbf;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      color: lighten($color: #fff, $amount: 10);
    }
  }

  .quick-links {
    ul {
      list-style: none;
      li {
        color: rgba(255, 255, 255, 0.5);
        padding: 10px 0;
      }
    }
  }

  .footer-actions {
    .footer-actions-des {
      color: #bfbfbf;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .footer-bottom {
    padding: 30px 0;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 20px;

    border-top: 1px solid #353535;

    @include media-up(sm) {
      flex-direction: row;
    }

    p {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 0;
      margin: 0;
    }

    .footer-bottom-right {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-flow: row nowrap;
      .hr-hor {
        display: block;
        width: 1px;
        height: 20px;
        margin: 0 10px;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
      a {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
