.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  animation-fill-mode: forwards;
}
.shine {
  animation: shine 5s ease-in-out infinite;
}
@keyframes pop-out {
  0% {
    transform: scale3d(0, 0, 1) translateY(0);
    opacity: 1;
  }
  40% {
    transform: scale3d(1, 1, 1) translateY(-40px);
    opacity: 1;
  }
  100% {
    transform: scale3d(1.5, 1.5, 1) translateY(-50px);
    opacity: 0;
  }
}
@keyframes fadeInOutStar {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.8;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes upAndDown {
  from {
    transform: translateY(-10px);
    box-shadow: inset 0px 50.8326px 72.9904px -46.9224px rgba(255, 255, 255, 0.5), inset 0px 9.1238px 14.3374px -5.2136px #ffffff,
      inset 0px -106.879px 88.6312px -83.4176px rgba(96, 68, 145, 0.3),
      inset 0px 127.733px 130.34px -62.5632px rgba(202, 172, 255, 0.3), inset 0px 5.2136px 23.4612px rgba(154, 146, 210, 0.3),
      inset 0px 1.3034px 52.136px rgba(227, 222, 255, 0.2);
  }
  to {
    transform: translateY(10px);
    box-shadow: inset 0px 10.8326px 72.9904px -46.9224px rgba(255, 255, 255, 0.5), inset 0px 9.1238px 14.3374px -5.2136px #ffffff,
      inset 0px -106.879px 88.6312px -83.4176px rgba(96, 68, 145, 0.3),
      inset 0px 17.733px 130.34px -62.5632px rgba(202, 172, 255, 0.3), inset 0px 5.2136px 23.4612px rgba(154, 146, 210, 0.3),
      inset 0px 10.3034px 12.136px rgba(227, 222, 255, 0.2);
  }
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Thunder {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
}
@keyframes colorPalette {
  0% {
    color: #ff2314;
  }
  25% {
    color: #2fff8d;
  }
  50% {
    color: #5c2fff;
  }
  75% {
    color: #ffbc1e;
  }
  100% {
    color: #e205ff;
  }
}
@keyframes scaleBackground {
  0% {
    transform: scale(1.1, 1);
    opacity: 1;
  }
  50% {
    transform: scale(1, 1.1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.1, 1);
    opacity: 1;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
    opacity: 0.3;
  }
}

@keyframes flashOnOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
