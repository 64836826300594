@import 'assets/css/variables.scss';

.wrapper-affiliate {
  max-width: 990px;
  min-height: 80vh;
  padding: 32px 15px 0 15px;
  margin: 0 auto;
  @media (min-width: 991px) {
    padding: 80px 15px;
  }

  .title-table-recent {
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-transform: capitalize;
    margin-top: 34px;

    @media (min-width: 991px) {
      font-size: 26px;
      line-height: 40px;
    }
  }

  .table-ref-floor {
    padding: 20px;
    margin-top: 24px;
    background: #00271a;
    border-radius: 24px;

    table {
      width: 100%;
      thead {
        tr {
          border-bottom: 0.1px solid #dadada;
          th {
            padding: 10px 12px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px 12px;
          }
        }
      }
    }
    .empty-table {
      width: 100%;
      min-height: 100px;
      text-align: center;
      padding: 30px 10px;
    }
  }

  .wrapper-info-affiliate {
    background: rgba(0, 81, 42, 0.5);
    -webkit-backdrop-filter: blur(300px);
    backdrop-filter: blur(300px);
    border-radius: 8px;
    padding: 24px 20px;

    &:not(:first-child) {
      margin-top: 32px;
    }

    .wrapper-info-affiliate-item {
      p {
        &:first-child {
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          @media (min-width: 991px) {
            font-size: 18px;
          }
        }

        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          font-size: 24px;
          line-height: 150%;
          font-weight: 700;
          background: linear-gradient(180deg, #ffb905 37.76%, #ffee97 62.67%, #ffb905 87.75%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          @media (min-width: 991px) {
            height: 90px;
            font-size: 32px;
          }
        }
      }
    }
  }
}
