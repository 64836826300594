.box-form-control {
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 16px;
  }
  .ant-select {
    width: 100% !important;

    .ant-select-selection-item {
      display: flex;
      align-items: center;

      img {
        width: 30px;
      }

      span {
        display: flex;
        align-items: center;
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .ant-select-selector {
      height: 42px;
      background-color: transparent !important;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      color: #ffffff;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      border-radius: 10px !important;
      padding: 0 0 0 20px;
      @media (min-width: 991px) {
        height: 54px;
      }
      &:hover {
        outline: none;
      }

      .ant-select-selection-search-input {
        height: 100%;
        padding: 10px;
      }
    }

    .ant-select-arrow {
      color: #ffffff;
      right: 32px;
    }

    .ant-select-selection-placeholder {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &:hover {
      .ant-select-selector {
        border-color: #55ee2c !important;
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border-color: #55ee2c !important;
      }
    }
  }

  @media (min-width: 991px) {
    p {
      font-size: 18px;
    }

    .ant-select {
      .ant-select-selector {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}

.ant-select-item {
  padding: 0 0 0 32px;
  display: flex;
  align-items: center;

  .ant-select-item-option-content {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }
  }
}
