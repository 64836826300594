.item-recent-transaction {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #34384c;
  .item-recent-transaction-view {
    color: #00f4a3;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  > div {
    p {
      margin-bottom: 16px;
      &:first-child {
        font-size: 12px;
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
        font-size: 12px;
      }

      &[data-status='COMPLETED'] {
        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #389201;
          border-radius: 3px;
          min-width: 80px;
          min-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      span {
        font-size: 14px;
      }
    }

    &:first-child {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #5d6588;

        &:first-child {
          font-weight: 600;
          font-size: 14px;
          color: #a5adcf;
        }
      }
      a {
        font-size: 14px;
      }
    }

    &:last-child {
      a {
        display: block;

        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #00f4a3;
        margin-bottom: 16px;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #389201;

        &:nth-child(3) {
          font-weight: 600;
        }
      }
    }
  }
}
