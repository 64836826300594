@import 'assets/css/variables.scss';

.auth-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  overflow-x: hidden;
  min-height: calc(100vh - 520px);

  @include media-up(lg) {
    overflow: hidden;
    margin: 0;
  }

  .auth-content-left {
    width: 100%;
    min-height: unset;
    position: relative;

    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    @include media-up(md) {
      min-height: 100vh;
    }
    @include media-up(lg) {
      width: 40%;
      display: flex;
      padding-left: 8%;
    }
    @include media-up(xxl) {
      width: 50%;
    }

    .auth-content-img {
      margin-top: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include media-up(lg) {
        // padding-top: 100px;
        justify-content: center;
        height: 60%;
        margin-top: 60px;
      }
      img {
        max-width: 150px;
        height: auto;
        object-fit: contain;

        @include media-up(lg) {
          max-width: 400px;
        }
      }
    }

    .auth-actions {
      width: 100%;
      height: 40%;
      display: block;
      @include media-up(lg) {
        display: none;
        padding-top: 50px;
      }
      & > a {
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 20px auto;
        text-align: center;
      }
    }
  }

  .auth-content-right {
    width: 100%;
    max-width: 100%;
    z-index: 0;
    position: relative !important;
    right: 0;
    top: 40%;
    border-radius: 36px 36px 0px 0px;
    padding: 50px 24px 0 24px;

    @include media-up(lg) {
      padding: 100px;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 36px 0px 0px 36px;
    }

    .auth-body-right {
      width: 100%;
      // height: 100%;
      display: none;

      @include media-up(lg) {
        display: block;
      }
    }
  }
}
